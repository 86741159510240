import React, {useEffect} from "react";
import {loadPaddle} from "../../../classes/paddle";

export const Payment = () => {
  useEffect(() => {
    loadPaddle().then(() => {});
  });

  return <></>;
};
