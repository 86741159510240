import React, {useEffect, useState} from "react";
import style from "./style.module.scss";
import {
  ActiveSubscription,
  BillingCycle,
  ScheduledChangeAction,
  SubscriptionPlan as SubscriptionPlanType,
} from "../../../classes/models.d";
import {Screen} from "../../../shared-components/screen/Screen";
import {MultiSelector} from "../../../shared-components/multi-selector/MultiSelector";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../../classes/network/network-caller";
import ApiEndpoints from "../../../classes/network/api-endpoints";
import {Plans} from "./plans/Plans";
import {AxiosResponse} from "axios";
import {loadPaddle} from "../../../classes/paddle";
import {SubscriptionCancelScheduleAttentionBox} from "../../../shared-components/subscription-cancel-schedule-attention-box/SubscriptionCancelScheduleAttentionBox";

export type PricePlan = {
  price: string;
  price_id: string;
};

export type SubscriptionPlanPricingPlans = {
  monthly: PricePlan;
  yearly: PricePlan;
};

export interface SubscriptionPlans {
  standard: {
    pricing_plans: SubscriptionPlanPricingPlans;
  };
  enterprise: {
    pricing_plans: SubscriptionPlanPricingPlans;
  };
}

export const SubscriptionPlan = () => {
  const [selectedBillingCycle, setSelectedBillingCycle] =
    useState<BillingCycle>(BillingCycle.MONTHLY);

  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState<SubscriptionPlans | undefined>();
  const [activeSubscription, setActiveSubscription] =
    useState<ActiveSubscription>();

  useEffect(() => {
    loadPaddle().then(init);
  }, []);

  const init = () => {
    setIsLoading(true);
    const promises: [
      Promise<AxiosResponse<SubscriptionPlans>>,
      Promise<AxiosResponse<ActiveSubscription>>,
    ] = [fetchPlans(), fetchActiveSubscription()];
    Promise.all(promises).then(response => {
      setPlans(response[0].data);
      setActiveSubscription(
        response[1].data || {plan_name: SubscriptionPlanType.STARTER},
      );
      setIsLoading(false);
    });
  };

  const fetchPlans = () =>
    callRequest<SubscriptionPlans>({
      method: NetworkCallerMethod.GET,
      api: ApiEndpoints.SUBSCRIPTION_PLANS,
    });

  const fetchActiveSubscription = () =>
    callRequest<ActiveSubscription>({
      method: NetworkCallerMethod.GET,
      api: ApiEndpoints.ACTIVE_SUBSCRIPTION,
    });

  return (
    <Screen>
      <div>
        {activeSubscription?.scheduled_change_action ===
          ScheduledChangeAction.CANCEL && (
          <div className={style.attentionBoxContainer}>
            <SubscriptionCancelScheduleAttentionBox
              activeSubscription={activeSubscription}
            />
          </div>
        )}
        <div className={style.titleContainer}>
          <h1>Subscription plans</h1>
          <p className={style.get2MonthsText}>
            Save up to 20% by subscribing yearly!
          </p>
        </div>
        <div className={style.billingCycles}>
          <MultiSelector
            options={[
              {
                label: "Yearly billing",
                value: BillingCycle.YEARLY,
              },
              {
                label: "Monthly billing",
                value: BillingCycle.MONTHLY,
              },
            ]}
            value={selectedBillingCycle}
            onSelect={value => setSelectedBillingCycle(value as BillingCycle)}
          />
        </div>
        <Plans
          isLoading={isLoading}
          activeSubscription={activeSubscription}
          plans={plans}
          billingCycle={selectedBillingCycle}
        />
      </div>
    </Screen>
  );
};
