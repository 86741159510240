import React, {useRef, useState} from "react";
import style from "./style.module.scss";
import {UserSearchInput} from "./user-search-input/UserSearchInput";
import {pushToast} from "../../../features/active-toasts/slice";
import {
  ModalModel,
  ResponseError,
  ResponseErrorType,
  ToastType,
  TopicModel,
  UserSearchResultModel,
} from "../../../classes/models.d";
import {useDispatch} from "react-redux";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../../classes/network/network-caller";
import ApiEndpoints from "../../../classes/network/api-endpoints";
import SimpleModal, {SimpleModalHandle} from "../simple-modal/SimpleModal";
import {createTopic} from "../../../features/topic-created/slice";
import {Toggle} from "../../toggle/Toggle";
import {AxiosError} from "axios";
import {pushModal} from "../../../features/active-modals/slice";
import {ModalIdentifier} from "../modals-map";

interface Props {
  modalData: ModalModel;
  topic?: TopicModel;
  onDelete?: () => void;
}

export const TopicModal: React.FC<Props> = ({modalData, onDelete, topic}) => {
  const dispatch = useDispatch();

  const isEditing = topic !== undefined;

  const [topicName, setTopicName] = useState<string>(topic?.name || "");
  const [description, setDescription] = useState<string>(
    topic?.description || "",
  );

  const [isAutoUpdateFromJira, setIsAutoUpdateFromJira] = useState<boolean>(
    topic?.auto_update_from_jira || false,
  );

  const initStakeholdersSearchResults =
    topic?.stakeholders?.map(stakeholder => ({
      resource_id: stakeholder.resource_id,
      resource_type: stakeholder.resource_type,
      display_name: stakeholder.display_name,
      avatar_url: stakeholder.avatar_url,
    })) || [];

  const [stakeholdersSearchResults, setStakeholdersSearchResults] = useState<
    UserSearchResultModel[]
  >(initStakeholdersSearchResults);

  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  const modalRef = useRef<SimpleModalHandle>(null);

  const onConfirm = () => {
    setIsConfirming(true);
    callRequest<TopicModel>({
      method: isEditing ? NetworkCallerMethod.PUT : NetworkCallerMethod.POST,
      api: isEditing
        ? `${ApiEndpoints.TOPICS}/ ${topic?.id}`
        : ApiEndpoints.TOPICS,
      data: {
        name: topicName,
        description,
        chosen_stakeholders: stakeholdersSearchResults,
        auto_update_from_jira: isAutoUpdateFromJira,
      },
    })
      .then(response => {
        setIsConfirming(false);
        dispatch(
          pushToast({
            title: isEditing
              ? "Topic updated successfully"
              : "Topic created successfully",
            type: ToastType.SUCCESS,
          }),
        );
        modalRef.current?.close();
        if (!isEditing) {
          dispatch(createTopic(response.data));
        }
      })
      .catch(handleCreateError);
  };

  const handleCreateError = (error: AxiosError<ResponseError>) => {
    setIsConfirming(false);

    if (
      error.response?.data.error_type == ResponseErrorType.REACHED_MAX_TOPICS
    ) {
      dispatch(
        pushModal({
          identifier: ModalIdentifier.SubscriptionPlan.AlertModal,
          ComponentProps: {
            responseErrorType: ResponseErrorType.REACHED_MAX_TOPICS,
          },
        }),
      );
      return;
    }

    dispatch(
      pushToast({
        title: isEditing ? "Failed to update topic" : "Failed to create topic",
        type: ToastType.ERROR,
      }),
    );
  };

  const isConfirmButtonEnabled =
    topicName.length > 0 && stakeholdersSearchResults.length > 0;

  const openDeletePromptModal = () => {
    dispatch(
      pushModal({
        identifier: ModalIdentifier.Common.DeleteTopicModal,
        ComponentProps: {
          topic,
          onDelete: () => {
            modalRef.current?.close();
            onDelete?.();
          },
        },
      }),
    );
  };

  return (
    <SimpleModal
      ref={modalRef}
      modalData={modalData}
      onCancel={isEditing ? openDeletePromptModal : undefined}
      onConfirm={onConfirm}
      cancelButtonVariant={isEditing ? "delete" : "just-text"}
      cancelButtonText={isEditing ? "Delete" : "Cancel"}
      confirmButtonText={isEditing ? "Save" : "Create"}
      isConfirmButtonEnabled={isConfirmButtonEnabled}
      isConfirmButtonLoading={isConfirming}
      title={isEditing ? `Edit ${topic.name}` : "Create topic"}
      additionalFooterContent={
        topic?.jira_issue_key && (
          <Toggle
            onSelect={setIsAutoUpdateFromJira}
            isSelected={isAutoUpdateFromJira}>
            Auto-Update from JIRA
          </Toggle>
        )
      }>
      {topic?.jira_issue_key && (
        <div style={{marginBottom: 24}}>
          <p className={style.sectionTitle}>
            JIRA epic: <b>{topic?.jira_issue_key}</b>
          </p>
        </div>
      )}
      <div>
        <p className={style.sectionTitle}>Topic name</p>
        <input
          defaultValue={topic?.name}
          onChange={event => setTopicName(event.target.value)}
          placeholder={"Type here"}
          className={style.sectionInput}
        />
      </div>
      <div className={style.section}>
        <p className={style.sectionTitle}>Description (optional)</p>
        <input
          defaultValue={topic?.description}
          onChange={event => setDescription(event.target.value)}
          placeholder={"Type here"}
          className={style.sectionInput}
        />
      </div>
      <div className={style.section}>
        <p className={style.sectionTitle} style={{marginBottom: 4}}>
          Stakeholders
        </p>
        <p className={style.sectionSubTitle}>
          List and drag stakeholders in order of priority, with the first name
          being the primary point of contact or the most responsible individual
          for this topic.
        </p>
        <UserSearchInput
          defaultSearchResults={initStakeholdersSearchResults}
          onChange={setStakeholdersSearchResults}
        />
      </div>
    </SimpleModal>
  );
};
