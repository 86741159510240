export type CurrentUserModel = {
  access_token: string;
  user_details: UserModel;
};

export type UserModel = {
  id: number;
  uuid: string;
  name: string;
  avatar_url: string;
  organization_member?: OrganizationMemberModel;
  intercom_user_hash?: string;
  is_jira_connected: boolean;
};

export enum Role {
  ADMIN = "ADMIN",
  USER = "USER",
}

export type OrganizationMemberModel = {
  role: Role;
  organization: OrganizationModel;
};

export type OrganizationModel = {
  id: number;
  name: string;
  logo_url: string;
  is_integrated_slack: boolean;
  subscription_plan: SubscriptionPlan;
};

export enum ToastType {
  SUCCESS = "success",
  ERROR = "error",
}

export type ToastModelWithoutId = {
  type: ToastType;
  title?: string;
  body?: string;
  duration?: number;
};

export interface ToastModel extends ToastModelWithoutId {
  id: string;
}

interface ModalModelWithoutId {
  identifier: string;
  ComponentProps?: any;
}

interface ModalModel extends ModalModelWithoutId {
  id: string;
}

export enum StakeholderResourceType {
  USER = "user",
  JIRA_USER = "jira_user",
}

export interface StakeholderModel {
  id: number;
  resource_id: number;
  resource_type: StakeholderResourceType;
  display_name: string;
  avatar_url: string;
}

export enum CreationSource {
  MANUAL = "manual",
  JIRA = "jira",
}

export interface TopicModel {
  id: number;
  name: string;
  description: string;
  stakeholders: StakeholderModel[];
  jira_issue_key: string;
  creation_source: CreationSource;
  auto_update_from_jira: boolean;
}

export interface UserSearchResultModel {
  resource_id: number;
  resource_type: StakeholderResourceType;
  display_name: string;
  avatar_url: string;
}

export interface OrganizationCredentials {
  client_id: string;
  client_secret_token: string;
}

export enum BillingCycle {
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export enum SubscriptionPlan {
  STARTER = "STARTER",
  STANDARD = "STANDARD",
  ENTERPRISE = "ENTERPRISE",
}

export enum ScheduledChangeAction {
  CANCEL = "cancel",
}

export type ActiveSubscription = {
  plan_name: SubscriptionPlan;
  scheduled_change_action?: ScheduledChangeAction;
  scheduled_change_effective_at?: string;
  price_id?: string;
  next_billed_at?: string;
};

export type BillingTransaction = {
  id: string;
  billed_at: string;
  amount: number;
};

export type BillingTransactionInvoice = {
  url: string;
};

export type SubscriptionManagementUrlResponse = {
  url: string;
};

export enum ResponseErrorType {
  REACHED_MAX_TOPICS = "REACHED_MAX_TOPICS",
}

export type ResponseError = {
  error_type: ResponseErrorType;
};
