import SimpleModal, {SimpleModalHandle} from "../../simple-modal/SimpleModal";
import React, {useRef, useState} from "react";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../../../classes/network/network-caller";
import ApiEndpoints from "../../../../classes/network/api-endpoints";
import {pushToast} from "../../../../features/active-toasts/slice";
import {ModalModel, ToastType, TopicModel} from "../../../../classes/models.d";
import {useDispatch} from "react-redux";

interface Props {
  modalData: ModalModel;
  topic: TopicModel;
  onDelete: () => void;
}

export const DeleteTopicModal: React.FC<Props> = ({
  modalData,
  topic,
  onDelete,
}) => {
  const dispatch = useDispatch();

  const modalRef = useRef<SimpleModalHandle>(null);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const deleteTopic = () => {
    setIsDeleting(true);
    callRequest({
      method: NetworkCallerMethod.DELETE,
      api: `${ApiEndpoints.TOPICS}/${topic?.id}`,
    })
      .then(() => {
        setIsDeleting(false);
        dispatch(
          pushToast({
            title: "Topic deleted successfully",
            type: ToastType.SUCCESS,
          }),
        );
        onDelete();
        modalRef.current?.close();
      })
      .catch(() => {
        setIsDeleting(false);
        dispatch(
          pushToast({
            title: "Failed to delete topic",
            type: ToastType.ERROR,
          }),
        );
      });
  };

  return (
    <SimpleModal
      ref={modalRef}
      title={`Delete ${topic.name}?`}
      modalData={modalData}
      onConfirm={deleteTopic}
      isConfirmButtonLoading={isDeleting}
      confirmButtonVariant={"delete"}
      confirmButtonText={"Delete"}
      isSmallModal={true}
      cancelButtonText="Cancel">
      <div>
        <p>
          You're about to permanently delete this topic and its stakeholders.
        </p>
      </div>
    </SimpleModal>
  );
};
