import SimpleModal from "../simple-modal/SimpleModal";
import {ModalModel, ResponseErrorType} from "../../../classes/models.d";
import React from "react";
import {useNavigate} from "react-router-dom";
import Screens from "../../../classes/screens";
import style from "./style.module.scss";

interface Props {
  modalData: ModalModel;
  responseErrorType: ResponseErrorType;
}

export const SubscriptionPlanAlertModal: React.FC<Props> = ({
  modalData,
  responseErrorType,
}) => {
  const navigate = useNavigate();

  const getTitle = () => {
    if (responseErrorType === ResponseErrorType.REACHED_MAX_TOPICS) {
      return "Topic Limit Reached";
    }
  };

  const getMessage = () => {
    if (responseErrorType === ResponseErrorType.REACHED_MAX_TOPICS) {
      return "You've reached the maximum number of topics allowed for your current subscription plan. To create more topics or explore additional features, consider upgrading your plan.";
    }
  };

  const goToSubscriptionPlans = () =>
    navigate(Screens.MANAGE_SUBSCRIPTION_PLAN);

  return (
    <SimpleModal
      title={getTitle()}
      onConfirm={goToSubscriptionPlans}
      modalData={modalData}
      confirmButtonText={"Manage subscription"}>
      <div className={style.iconContainer}>
        <img
          className={style.icon}
          src={"/assets/images/warning.svg"}
          width={50}
        />
      </div>
      <p>{getMessage()}</p>
    </SimpleModal>
  );
};
