import React from "react";

import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../../classes/network/network-caller";
import ApiEndpoints from "../../../classes/network/api-endpoints";
import style from "./style.module.scss";
import Screens from "../../../classes/screens";
import {ToastType} from "../../../classes/models.d";
import {generateRedirectToastParams} from "../../../classes/utils";

export const SlackIntegrationRedirect = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const error = queryParams.get("error");

  useEffect(() => {
    if (code) {
      callRequest({
        method: NetworkCallerMethod.POST,
        api: ApiEndpoints.SLACK_ORGANIZATION_INTEGRATE,
        data: {
          code,
        },
      }).then(() => {
        window.location.href = `${
          Screens.SETTINGS
        }?${generateRedirectToastParams({
          title: "Slack was integrated successfully",
          type: ToastType.SUCCESS,
        })}`;
      });
    }

    if (error && error.length > 0) {
      window.location.href = `${Screens.SETTINGS}?${generateRedirectToastParams(
        {title: "Slack integration failed", type: ToastType.ERROR},
      )}`;
    }
  }, []);

  return (
    <div className={style.container}>
      <div>
        <img src={"/assets/images/slack-colored.svg"} />
      </div>
      <p>Integrating Slack to your organization....</p>
      <div className={`loader ${style.loader}`} />
    </div>
  );
};
