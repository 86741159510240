import SimpleModal, {
  SimpleModalHandle,
} from "../../modals/simple-modal/SimpleModal";
import React, {useRef} from "react";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../../classes/network/network-caller";
import ApiEndpoints from "../../../classes/network/api-endpoints";
import {useDispatch} from "react-redux";
import {pushToast} from "../../../features/active-toasts/slice";
import {ModalModel, ToastType} from "../../../classes/models.d";

interface Props {
  modalData: ModalModel;
}

export const ConfirmationModal: React.FC<Props> = ({modalData}) => {
  const dispatch = useDispatch();

  const ref = useRef<SimpleModalHandle>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const confirm = () => {
    setIsLoading(true);
    callRequest({
      method: NetworkCallerMethod.POST,
      api: ApiEndpoints.KEEP_SUBSCRIPTION_ACTIVE,
    }).then(() => {
      dispatch(
        pushToast({
          title: "Subscription kept active",
          type: ToastType.SUCCESS,
        }),
      );
      ref.current?.close();
      setIsLoading(false);
    });
  };

  return (
    <SimpleModal
      ref={ref}
      modalData={modalData}
      title={"Keep subscription active?"}
      onConfirm={confirm}
      isConfirmButtonLoading={isLoading}>
      <p>Are you sure you want to keep your subscription active?</p>
    </SimpleModal>
  );
};
