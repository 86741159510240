import React, {useEffect, useState} from "react";
import {Screen} from "../../shared-components/screen/Screen";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../classes/network/network-caller";
import ApiEndpoints from "../../classes/network/api-endpoints";
import {Role, UserModel} from "../../classes/models";
import style from "./style.module.scss";
import {Member} from "./member/Member";

interface UserAndPermissionUserModel extends UserModel {
  email: string;
}

export interface UserAndPermissionMember {
  id: string;
  role: Role;
  is_suspended: boolean;
  user: UserAndPermissionUserModel;
}

export const UsersAndPermissions = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [members, setMembers] = useState<UserAndPermissionMember[]>([]);

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = () => {
    setIsLoading(true);
    callRequest<UserAndPermissionMember[]>({
      method: NetworkCallerMethod.GET,
      api: ApiEndpoints.ORGANIZATION_MEMBERS,
    }).then(response => {
      setMembers(response.data);
      setIsLoading(false);
    });
  };

  return (
    <Screen
      title={"Users and permissions"}
      titleIconUrl={"/assets/images/user-icon.svg"}>
      {isLoading ? (
        <div className={"loader page-loader"}></div>
      ) : (
        <>
          <table className={style.table}>
            <thead>
              <tr>
                <th role={"row"}>
                  <p>Member</p>
                </th>
                <th role={"row"}>
                  <p>Role</p>
                </th>
                <th>
                  <p>Action</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {members.map(member => (
                <Member key={member.id} data={member} />
              ))}
            </tbody>
          </table>
        </>
      )}
    </Screen>
  );
};
