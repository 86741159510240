import {NavigateFunction} from "react-router/dist/lib/hooks";
import {CurrentUserModel, UserModel} from "./models";
import Screens from "./screens";

export const LOCAL_STORAGE_CURRENT_USER_KEY = "CURRENT_USER";

const getCurrentUserFromLocalStorage = () =>
  localStorage.getItem(LOCAL_STORAGE_CURRENT_USER_KEY);

const isSignedIn = () => !!getCurrentUserFromLocalStorage();

const getCurrentUser = (): CurrentUserModel =>
  JSON.parse(getCurrentUserFromLocalStorage() || "");

const saveCurrentUserToLocalStorage = (currentUser: CurrentUserModel) =>
  localStorage.setItem(
    LOCAL_STORAGE_CURRENT_USER_KEY,
    JSON.stringify(currentUser),
  );

const updateUserDetailsToLocalStorage = (userDetails: UserModel) => {
  const currentCurrentUser = getCurrentUser();
  currentCurrentUser.user_details = userDetails;
  saveCurrentUserToLocalStorage(currentCurrentUser);
};

const signOut = (navigate: NavigateFunction) => {
  localStorage.clear();
  navigate(Screens.LOGIN);
};

export default {
  saveCurrentUserToLocalStorage,
  updateUserDetailsToLocalStorage,
  getCurrentUser,
  isSignedIn,
  signOut,
};
