import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TopicModel} from "../../classes/models";

interface TopicsState {
  topicCreated: TopicModel | null;
}

const initialState: TopicsState = {
  topicCreated: null,
};

export const slice = createSlice({
  name: "topicCreated",
  initialState,
  reducers: {
    createTopic: (state, action: PayloadAction<TopicModel | null>) => {
      state.topicCreated = action.payload;
    },
  },
});

export const {createTopic} = slice.actions;

export default slice.reducer;
