import style from "../../style.module.scss";
import {Button} from "../../../../shared-components/button/Button";
import React, {useEffect, useState} from "react";
import Env from "../../../../classes/env/env";
import Screens from "../../../../classes/screens";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../features/current-user/selectors";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../../../classes/network/network-caller";
import ApiEndpoints from "../../../../classes/network/api-endpoints";
import {fetchCurrentUserDetails} from "../../../../api/user-api";
import {pushToast} from "../../../../features/active-toasts/slice";
import {
  OrganizationCredentials,
  SubscriptionPlan,
  ToastType,
} from "../../../../classes/models.d";
import {setCurrentUserDetails} from "../../../../features/current-user/slice";
import {pushModal} from "../../../../features/active-modals/slice";
import {ModalIdentifier} from "../../../../shared-components/modals/modals-map";
import {AttentionBox} from "../../../../shared-components/attention-box/AttentionBox";
import {
  removeIntercomScript,
  loadIntercom,
} from "../../../../classes/intercom/intercom";

export const OrganizationTabScreen = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const [organizationCredentials, setOrganizationCredentials] =
    useState<OrganizationCredentials | null>(null);

  const [isShowClientSecret, setIsShowClientSecret] = useState<boolean>(false);

  const [isDisablingSlackIntegration, setIsDisablingSlackIntegration] =
    useState<boolean>(false);

  useEffect(() => {
    fetchCredentials();
    loadIntercom();
    return () => {
      removeIntercomScript();
    };
  }, []);

  const fetchCredentials = () => {
    callRequest<OrganizationCredentials>({
      method: NetworkCallerMethod.GET,
      api: ApiEndpoints.ORGANIZATION_CREDENTIALS,
    }).then(response => {
      setOrganizationCredentials(response.data);
    });
  };

  const getSlackIntegrationRedirectUrl = (): string => {
    const clientId = Env.SLACK_CLIENT_ID;
    const redirectUri = encodeURIComponent(
      `${Env.WEBAPP_URL}${Screens.SLACK_INTEGRATION_REDIRECT}`,
    );
    const userScopes = "identity.basic,identity.avatar,identity.email";
    const scopes = "users:read,chat:write,team:read";
    return `https://slack.com/oauth/v2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes}&user_scope=${userScopes}`;
  };

  const getJiraIntegrationRedirectUrl = (): string => {
    return "https://developer.atlassian.com/console/install/d8af7cf6-9b5f-4bb0-af59-80af3917c4bf?signature=AYABeG6ubs2z454tcZfKudLaVdgAAAADAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6NzA5NTg3ODM1MjQzOmtleS83ZjcxNzcxZC02OWM4LTRlOWItYWU5Ny05MzJkMmNhZjM0NDIAuAECAQB4KZa3ByJMxgsvFlMeMgRb2S0t8rnCLHGz2RGbmY8aB5YB5ekjNYWEwHj4zKaeaHPzzAAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDKvC8S8bbX%2Bdn3heAAIBEIA7OIMfN%2FysN1BHQu7xN86t809uz019jizuc6k3IvJufbO5R38G8WikcC9Fi3%2BUisZM0b3XMtNPraDku%2B0AB2F3cy1rbXMAS2Fybjphd3M6a21zOmV1LXdlc3QtMTo3MDk1ODc4MzUyNDM6a2V5LzU1OWQ0NTE2LWE3OTEtNDdkZi1iYmVkLTAyNjFlODY4ZWE1YwC4AQICAHhHSGfAZiYvvl%2F9LQQFkXnRjF1ris3bi0pNob1s2MiregH6Ccv5SDldjnggeA7nliasAAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMHy1l3gVvtpnHJbHSAgEQgDsHpsph849RGiTyzWnyGTlatifhBl7Ce%2BwT4UoVxwI8k5w3z5hc7enGAk%2BbDyuhmiXyhen6CB7p%2FDpzHgAHYXdzLWttcwBLYXJuOmF3czprbXM6dXMtd2VzdC0yOjcwOTU4NzgzNTI0MzprZXkvM2M0YjQzMzctYTQzOS00ZmNhLWEwZDItNDcyYzE2ZWRhZmRjALgBAgIAePadDOCfSw%2BMRVmOIDQhHhGooaxQ%2FiwGaLB334n1X9RCAdZK3WH0iTVaCKjnXXUH2ysAAAB%2BMHwGCSqGSIb3DQEHBqBvMG0CAQAwaAYJKoZIhvcNAQcBMB4GCWCGSAFlAwQBLjARBAx4VY5numeDz%2BrxhbkCARCAO5ZUAC%2BCCTg4y5eVkREcWek2jmjO3lqfWwzUx%2FtIIi3PT%2BFJXtt4lutwtm2Enon1lfxvr2MipQCiAjFsAgAAAAAMAAAQAAAAAAAAAAAAAAAAAMpR%2FrNFp3HfjX2yT4QATgL%2F%2F%2F%2F%2FAAAAAQAAAAAAAAAAAAAAAQAAADKV9nDhtEUJw9Ts38AzKuGnat%2BFKBez1444ZVhiNaXTbZzBm4nw6WuDQTB8RWTluejA1ra7E5BhGtS6ywM%2BYlM6tX0%3D&product=jira";
  };

  const organization =
    currentUser.userDetails?.organization_member?.organization;

  const isIntegratedSlack = organization?.is_integrated_slack;

  const disableSlackIntegration = () => {
    setIsDisablingSlackIntegration(true);
    callRequest({
      api: ApiEndpoints.ORGANIZATION_DISABLE_SLACK_INTEGRATION,
      method: "POST",
    }).then(() => {
      fetchCurrentUserDetails().then(data => {
        setIsDisablingSlackIntegration(false);
        dispatch(
          pushToast({
            title: "Slack integration disabled successfully",
            type: ToastType.SUCCESS,
          }),
        );
        dispatch(setCurrentUserDetails(data));
      });
    });
  };

  const openOrganizationModal = () =>
    dispatch(
      pushModal({
        identifier: ModalIdentifier.Common.OrganizationModal,
      }),
    );

  const openBillingModal = () =>
    dispatch(
      pushModal({
        identifier: ModalIdentifier.Common.BillingModal,
      }),
    );

  const isAllowedToIntegrateJira = [
    SubscriptionPlan.STANDARD,
    SubscriptionPlan.ENTERPRISE,
  ].includes(organization?.subscription_plan || SubscriptionPlan.STARTER);

  return (
    <div>
      <div className={style.settingsItemContainer}>
        <div>
          <p className={style.settingsItemTitle}>Edit details</p>
          <p className={style.settingsItemSubTitle}>
            Add a logo or edit your organization's name.
          </p>
        </div>
        <Button onClick={openOrganizationModal} variant={"filled-secondary"}>
          Edit
        </Button>
      </div>
      <div className={style.settingsItemContainer}>
        <div>
          <div className={style.settingsItemTitleContainer}>
            <p className={style.settingsItemTitle}>Slack integration</p>
            {isIntegratedSlack && (
              <span className={"badge success"}>INTEGRATED</span>
            )}
          </div>
          <p className={style.settingsItemSubTitle}>
            Easily find relevant stakeholders on Slack.
          </p>
        </div>
        {isIntegratedSlack ? (
          <Button
            onClick={disableSlackIntegration}
            isLoading={isDisablingSlackIntegration}
            iconUrl={"/assets/images/slack-colored.svg"}
            variant={"filled-secondary"}>
            Disable
          </Button>
        ) : (
          <Button
            href={getSlackIntegrationRedirectUrl()}
            variant={"filled-secondary"}
            iconUrl={"/assets/images/slack-colored.svg"}>
            Integrate
          </Button>
        )}
      </div>
      <div
        className={`${style.settingsItemContainer} ${style.jiraIntegrationContainer}`}>
        <div>
          <div className={style.settingsItemTitleContainer}>
            <p className={style.settingsItemTitle}>JIRA integration</p>
          </div>
          <p className={style.settingsItemSubTitle}>
            Sync your JIRA epics into Who2. To get started, install our app in
            your JIRA workspace. All configuration, including syncing epics, can
            be easily managed within the app settings in your workspace.
          </p>
        </div>
        <div className={style.jiraIntegrateButtonContainer}>
          <div>
            <Button
              target={"_blank"}
              enabled={isAllowedToIntegrateJira}
              href={getJiraIntegrationRedirectUrl()}
              variant={"filled-secondary"}
              iconUrl={"/assets/images/jira-colored.svg"}>
              Install App
            </Button>
          </div>
          {!isAllowedToIntegrateJira && (
            <AttentionBox className={style.jiraAvailabilityBox} type={"info"}>
              <p>Available for Standard and Enterprise plans.</p>
            </AttentionBox>
          )}
        </div>
      </div>
      <div className={style.settingsItemContainer}>
        <div>
          <div className={style.settingsItemTitleContainer}>
            <p className={style.settingsItemTitle}>Users and permissions</p>
          </div>
          <p className={style.settingsItemSubTitle}>
            Manage your team and their Who2 permissions.
          </p>
        </div>
        <Button
          href={Screens.USERS_AND_PERMISSIONS}
          variant={"filled-secondary"}>
          Manage
        </Button>
      </div>
      <div className={style.settingsItemContainer}>
        <div>
          <div className={style.settingsItemTitleContainer}>
            <p className={style.settingsItemTitle}>Billing</p>
          </div>
          <p className={style.settingsItemSubTitle}>
            Manage your subscription plan and view your billings.
          </p>
        </div>
        <Button onClick={openBillingModal} variant={"filled-secondary"}>
          Manage
        </Button>
      </div>
      <div className={style.settingsItemContainer}>
        <div>
          <div className={style.settingsItemTitleContainer}>
            <p className={style.settingsItemTitle}>Credentials</p>
          </div>
          <div className={style.credentialsContainer}>
            <div className={style.credentialsClientIdContainer}>
              <p>Client ID</p>
              <input
                disabled={true}
                value={organizationCredentials?.client_id}
              />
            </div>
            <div className={style.credentialsClientSecretContainer}>
              <p>Client Secret</p>
              <div>
                <input
                  disabled={true}
                  value={organizationCredentials?.client_secret_token}
                  type={isShowClientSecret ? "text" : "password"}
                />
                <Button
                  className={style.viewClientSecretButton}
                  onClick={() => setIsShowClientSecret(!isShowClientSecret)}>
                  {isShowClientSecret ? "Hide" : "Show"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
