import React, {useEffect} from "react";

import {useLocation, useNavigate} from "react-router-dom";
import {
  callRequest,
  NetworkCallerError,
  NetworkCallerMethod,
} from "../../../classes/network/network-caller";
import ApiEndpoints from "../../../classes/network/api-endpoints";
import style from "./style.module.scss";
import {ToastType, UserModel} from "../../../classes/models.d";
import {useDispatch} from "react-redux";
import {setCurrentUserDetails} from "../../../features/current-user/slice";
import {AxiosError} from "axios";
import Screens from "../../../classes/screens";
import {generateRedirectToastParams} from "../../../classes/utils";

export const SlackUserSignInRedirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const error = queryParams.get("error");

  useEffect(() => {
    if (code) {
      callRequest({
        method: NetworkCallerMethod.POST,
        api: ApiEndpoints.SLACK_USER_SIGN_IN,
        data: {
          code,
        },
      })
        .then(() => {
          callRequest<UserModel>({
            method: NetworkCallerMethod.GET,
            api: ApiEndpoints.ME,
          }).then(response => {
            dispatch(setCurrentUserDetails(response.data));
            navigate(Screens.ALL_TOPICS);
          });
        })
        .catch((error: AxiosError<NetworkCallerError>) => {
          if (error.response?.data.error_message) {
            navigate({
              pathname: Screens.SELECT_ORGANIZATION,
              search: `?error_message=${error.response.data.error_message}`,
            });
          }
        });
    }

    if (error && error.length > 0) {
      window.location.href = `${
        Screens.SELECT_ORGANIZATION
      }?${generateRedirectToastParams({
        title: "Failed to sign in with Slack",
        type: ToastType.ERROR,
      })}`;
    }
  }, []);

  return (
    <div className={style.container}>
      <div>
        <img src={"/assets/images/slack-colored.svg"} />
      </div>
      <p>Signing in using your Slack....</p>
      <div className={`loader ${style.loader}`} />
    </div>
  );
};
