import React, {PropsWithChildren, useEffect, useRef, useState} from "react";
import style from "./style.module.scss";

interface Props {
  className?: string;
  refElement?: React.RefObject<any>;
}

const TOOLTIP_OFFSET = 20;
const TOOLTIP_MAX_OFFSET = 50;

export const Tooltip: React.FC<PropsWithChildren<Props>> = ({
  className,
  children,
  refElement,
}) => {
  const ref = useRef<any>(null);

  const [toolTipLayout, setToolTipLayout] = useState({width: 0, height: 0});

  const [refElementCoordinates, setRefElementCoordinates] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setToolTipLayout({width: rect.width, height: rect.height});
    }
  }, [ref]);

  useEffect(() => {
    if (refElement?.current) {
      const rect = refElement?.current.getBoundingClientRect();
      console.log(rect);
      setRefElementCoordinates({
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
      });
    }
  }, [refElement]);

  const getLeft = (): number => {
    const {x, width} = refElementCoordinates;
    return x + toolTipLayout.width + TOOLTIP_MAX_OFFSET > window.innerWidth
      ? x - toolTipLayout.width - TOOLTIP_OFFSET / 2
      : x + width / 2 + TOOLTIP_OFFSET;
  };

  const getTop = (): number => {
    const {y, height} = refElementCoordinates;
    return y + toolTipLayout.height + TOOLTIP_MAX_OFFSET > window.innerHeight
      ? y - toolTipLayout.height
      : y + height / 2;
  };

  return (
    <div
      ref={ref}
      className={`${style.container} ${className}`}
      style={{
        zIndex: 1000000,
        top: getTop(),
        left: getLeft(),
      }}>
      {children}
    </div>
  );
};
