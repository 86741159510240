import React, {PropsWithChildren} from "react";
import style from "./style.module.scss";

export type ButtonVariant =
  | "filled-main"
  | "filled-secondary"
  | "outlined"
  | "just-text"
  | "delete"
  | "slack"
  | "nav-link-selected";

interface Props {
  target?: string;
  href?: string;
  onClick?: () => void;
  iconUrl?: string;
  additionalIconUrl?: string;
  className?: string;
  variant?: ButtonVariant;
  isLoading?: boolean;
  enabled?: boolean;
}

export const Button: React.FC<PropsWithChildren<Props>> = ({
  target,
  href,
  onClick,
  iconUrl,
  additionalIconUrl,
  variant = "filled-main",
  children,
  className,
  isLoading,
  enabled = true,
}) => {
  const getStyleClassByVariant = () => {
    if (variant == "filled-main") {
      return style.filledMain;
    }
    if (variant == "filled-secondary") {
      return style.filledSecondary;
    }
    if (variant == "outlined") {
      return style.outlined;
    }
    if (variant == "just-text") {
      return style.justText;
    }
    if (variant == "nav-link-selected") {
      return style.navLinkSelected;
    }
    if (variant == "delete") {
      return style.delete;
    }
    if (variant == "slack") {
      return style.slack;
    }
  };

  return (
    <a
      target={target || undefined}
      href={href}
      onClick={onClick}
      className={`${style.button} ${
        isLoading && style.isLoading
      } ${getStyleClassByVariant()} ${className} ${
        !enabled && style.disabled
      }`}>
      {iconUrl && <img src={iconUrl} className={style.icon} />}
      <p>{children}</p>
      {additionalIconUrl && (
        <img src={additionalIconUrl} className={style.additionalIcon} />
      )}
    </a>
  );
};
