const SIGN_IN = "/v1/user/sign-in";
const CREATE_ORGANIZATION = "/v1/user/organization/create";
const TOPICS = "/v1/user/topics";
const MY_TOPICS = "/v1/user/my-topics";
const MY_ORGANIZATION = "/v1/user/my-organization";
const ME = "/v1/user/me";
const SEARCH_USERS = "/v1/user/search-users";
const SLACK_ORGANIZATION_INTEGRATE = "/v1/user/organization/slack/integrate";
const SLACK_USER_SIGN_IN = "/v1/user/slack/sign-in";
const JIRA_USER_SIGN_IN = "/v1/user/jira/sign-in";
const ORGANIZATION_DISABLE_SLACK_INTEGRATION =
  "/v1/user/organization/slack/disable-connection";
const USER_REMOVE_JIRA_CONNECTION = "/v1/user/remove-jira-connection";
const ORGANIZATION_CREDENTIALS = "/v1/user/organization/credentials";
const SUBSCRIPTION_PLANS = "/v1/common/subscription-plans";
const ACTIVE_SUBSCRIPTION = "/v1/user/organization/subscription/active";
const TRANSACTIONS = "/v1/user/organization/billing/transactions";
const TRANSACTION_INVOICE = (id: string) =>
  `/v1/user/organization/billing/transaction/${id}/invoice`;
const CHANGE_SUBSCRIPTION = "/v1/user/organization/subscription/change";
const CANCEL_SUBSCRIPTION = "/v1/user/organization/subscription/cancel";
const UPDATE_PAYMENT_METHOD =
  "/v1/user/organization/subscription/update-payment-method";
const KEEP_SUBSCRIPTION_ACTIVE =
  "/v1/user/organization/subscription/keep-active";
const ORGANIZATION_MEMBERS = "/v1/user/organization/members";
const ORGANIZATION_MEMBER = (id: string) => `/v1/user/organization/members/${id}`;

export default {
  SIGN_IN,
  CREATE_ORGANIZATION,
  TOPICS,
  MY_TOPICS,
  MY_ORGANIZATION,
  SLACK_ORGANIZATION_INTEGRATE,
  SLACK_USER_SIGN_IN,
  ME,
  SEARCH_USERS,
  JIRA_USER_SIGN_IN,
  ORGANIZATION_DISABLE_SLACK_INTEGRATION,
  USER_REMOVE_JIRA_CONNECTION,
  ORGANIZATION_CREDENTIALS,
  SUBSCRIPTION_PLANS,
  ACTIVE_SUBSCRIPTION,
  TRANSACTIONS,
  TRANSACTION_INVOICE,
  CHANGE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  UPDATE_PAYMENT_METHOD,
  KEEP_SUBSCRIPTION_ACTIVE,
  ORGANIZATION_MEMBERS,
  ORGANIZATION_MEMBER
};
