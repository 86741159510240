import axios, {AxiosError, AxiosPromise, AxiosResponse} from "axios";
import Env from "../env/env";
import CurrentUser from "../current-user";

interface NetworkCallerParams {
  api: string;
  method: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  data?: any;
}

export enum NetworkCallerMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export type NetworkCallerError = {
  error_message: string;
};

export function callRequest<T>(params: NetworkCallerParams): AxiosPromise<T> {
  const url = `${Env.API_URL}${params.api}`;
  return new Promise<AxiosResponse<T>>((resolve, reject) => {
    axios({
      method: params.method,
      url,
      data: params.method != NetworkCallerMethod.GET ? params.data : undefined,
      params: params.method === NetworkCallerMethod.GET && params.data,
      headers: {
        Accept: "application/json",
        "Content-Type":
          params.data instanceof FormData
            ? "multipart/form-data"
            : "application/json",
        Authorization:
          CurrentUser.isSignedIn() && CurrentUser.getCurrentUser().access_token,
      },
    })
      .then(axiosResponse => resolve(axiosResponse))
      .catch((error: AxiosError) => reject(error));
  });
}
