import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CurrentUserModel, UserModel} from "../../classes/models";
import CurrentUser from "../../classes/current-user";

export interface CurrentUserState {
  accessToken: string | null;
  userDetails: UserModel | null;
}

const initialState: CurrentUserState = {
  accessToken: null,
  userDetails: null,
};

export const slice = createSlice({
  name: "current-user",
  initialState,
  reducers: {
    setCurrentUser: (
      state: CurrentUserState,
      action: PayloadAction<CurrentUserModel>,
    ) => {
      const {access_token, user_details} = action.payload;
      state.accessToken = access_token;
      state.userDetails = user_details;
      CurrentUser.saveCurrentUserToLocalStorage(action.payload);
    },
    setCurrentUserDetails: (
      state: CurrentUserState,
      action: PayloadAction<UserModel>,
    ) => {
      state.userDetails = action.payload;
      CurrentUser.updateUserDetailsToLocalStorage(action.payload);
    },
  },
});

export const {setCurrentUser, setCurrentUserDetails} = slice.actions;

export default slice.reducer;
