import React, {PropsWithChildren} from "react";
import style from "./style.module.scss";

interface Props {
  className?: string;
  isSelected?: boolean;
  onSelect: (isSelected: boolean) => void;
}

export const Toggle: React.FC<PropsWithChildren<Props>> = ({
  className,
  children,
  isSelected = false,
  onSelect,
}) => {
  const toggle = () => {
    onSelect(!isSelected);
  };

  return (
    <div className={`${style.container} ${className}`}>
      <input
        onClick={toggle}
        defaultChecked={isSelected}
        className={style.checkbox}
        type={"checkbox"}
        id="switch"
      />
      <label className={style.checkboxLabel} htmlFor="switch" />
      <span className={style.title}>{children}</span>
    </div>
  );
};
