import style from "../style.module.scss";
import {Button} from "../../../shared-components/button/Button";
import React, {useState} from "react";
import CurrentUser from "../../../classes/current-user";
import {useNavigate} from "react-router-dom";
import Env from "../../../classes/env/env";
import Screens from "../../../classes/screens";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUser} from "../../../features/current-user/selectors";
import {callRequest} from "../../../classes/network/network-caller";
import {pushToast} from "../../../features/active-toasts/slice";
import {ToastType} from "../../../classes/models.d";
import ApiEndpoints from "../../../classes/network/api-endpoints";
import {fetchCurrentUserDetails} from "../../../api/user-api";
import {setCurrentUserDetails} from "../../../features/current-user/slice";

export const PersonalTabScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector(selectCurrentUser);

  const [isRemovingJiraConnection, setIsRemovingJiraConnection] =
    useState<boolean>(false);

  const getRedirectUrl = (): string => {
    const clientId = Env.JIRA_CLIENT_ID;
    const redirectUri = encodeURIComponent(
      `${Env.WEBAPP_URL}${Screens.JIRA_OAUTH_REDIRECT}`,
    );
    const scope = encodeURIComponent("read:me offline_access");
    return `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&response_type=code&prompt=consent`;
  };

  const signOut = () => {
    CurrentUser.signOut(navigate);
  };

  const removeJiraConnection = () => {
    setIsRemovingJiraConnection(true);
    callRequest({
      api: ApiEndpoints.USER_REMOVE_JIRA_CONNECTION,
      method: "POST",
    }).then(() => {
      fetchCurrentUserDetails().then(data => {
        setIsRemovingJiraConnection(false);
        dispatch(
          pushToast({
            title: "JIRA connection removed",
            type: ToastType.SUCCESS,
          }),
        );
        dispatch(setCurrentUserDetails(data));
      });
    });
  };

  return (
    <div>
      <div className={style.settingsItemContainer}>
        <div>
          <div className={style.settingsItemTitleContainer}>
            <p className={style.settingsItemTitle}>Connect to JIRA</p>
          </div>
          <p className={style.settingsItemSubTitle}>
            Connect your JIRA account to your Who2 account.
          </p>
        </div>
        {currentUser.userDetails?.is_jira_connected ? (
          <Button
            onClick={removeJiraConnection}
            isLoading={isRemovingJiraConnection}
            iconUrl={"/assets/images/jira-colored.svg"}
            variant={"filled-secondary"}>
            Remove connection
          </Button>
        ) : (
          <Button
            href={getRedirectUrl()}
            variant={"filled-secondary"}
            iconUrl={"/assets/images/jira-colored.svg"}>
            Connect
          </Button>
        )}
      </div>
      <div className={style.settingsItemContainer}>
        <div>
          <div className={style.settingsItemTitleContainer}>
            <p className={style.settingsItemTitle}>Sign out</p>
          </div>
          <p className={style.settingsItemSubTitle}>
            Sign out of your Who2 account.
          </p>
        </div>
        <Button onClick={signOut} variant={"filled-secondary"}>
          Sign out
        </Button>
      </div>
    </div>
  );
};
