import style from "./style.module.scss";
import React, {useRef, useState} from "react";
import {Tooltip} from "../../../../../../shared-components/tooltip/Tooltip";

export interface Feature {
  title: string;
  description?: string;
}

interface Props {
  feature: Feature;
}

export const Feature: React.FC<Props> = ({feature}) => {
  const questionMarkRef = useRef(null);

  const [isShowDescriptionToolTip, setIsShowDescriptionToolTip] =
    useState(false);

  const showDescriptionToolTip = () => setIsShowDescriptionToolTip(true);

  const hideDescriptionToolTip = () => setIsShowDescriptionToolTip(false);

  return (
    <>
      {isShowDescriptionToolTip && (
        <Tooltip refElement={questionMarkRef}>
          <div className={style.toolTipContent}>
            <p>{feature.description}</p>
          </div>
        </Tooltip>
      )}
      <div className={style.container}>
        <img src={"/assets/images/pricing-checkmark.svg"} width={20} />
        <div className={style.titleContainer}>
          <p className={style.title}>{feature.title}</p>
          {feature.description && (
            <img
              src={"/assets/images/help.svg"}
              ref={questionMarkRef}
              onMouseOver={showDescriptionToolTip}
              onMouseOut={hideDescriptionToolTip}
              className={style.questionMark}></img>
          )}
        </div>
      </div>
    </>
  );
};
