import React from "react";
import style from "./style.module.scss";
import Screens from "../../classes/screens";
import {Link, useLocation} from "react-router-dom";
import {Button} from "../button/Button";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUser} from "../../features/current-user/selectors";
import {Logo} from "../logo/Logo";
import {pushModal} from "../../features/active-modals/slice";
import {ModalIdentifier} from "../modals/modals-map";

export const MenuBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const currentUser = useSelector(selectCurrentUser);

  const isLinkActive = (screen: string) => location.pathname === screen;

  const organization =
    currentUser.userDetails?.organization_member?.organization;

  const logoUrl = organization?.logo_url;

  const createTopic = () =>
    dispatch(
      pushModal({
        identifier: ModalIdentifier.Common.TopicModal,
      }),
    );

  return (
    <div className={style.container}>
      <div className={`page-width ${style.headerContent}`}>
        <div className={style.leftSection}>
          <a href={"/"} className={style.logo}>
            {logoUrl ? <img height={30} src={logoUrl} /> : <Logo />}
          </a>
          <Link
            to={Screens.MY_TOPICS}
            className={`${style.navLink} ${
              isLinkActive(Screens.MY_TOPICS) && style.active
            }`}>
            Topics I'm involved in
          </Link>
          <Link
            to={Screens.ALL_TOPICS}
            className={`${style.navLink} ${
              isLinkActive(Screens.ALL_TOPICS) && style.active
            }`}>
            All topics
          </Link>
          <a onClick={createTopic} className={style.navLink}>
            Create topic
          </a>
        </div>
        <div className={style.rightSection}>
          <Button
            href={Screens.SETTINGS}
            variant={"filled-secondary"}
            className={style.rightSectionItem}>
            Settings
          </Button>
          <div className={style.userProfileContainer}>
            <div>
              <img
                className={style.userProfileImage}
                src={currentUser.userDetails?.avatar_url}
              />
            </div>
            <div className={style.userNameContainer}>
              <p className={style.userName}>{currentUser.userDetails?.name}</p>
              <p className={style.organizationName}>{organization?.name}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
