import {CurrentUserState} from "./features/current-user/slice";
import CurrentUser from "./classes/current-user";

const getCurrentUser = (): {currentUser: CurrentUserState} => {
  if (CurrentUser.isSignedIn()) {
    const parsedCurrentUser = CurrentUser.getCurrentUser();
    return {
      currentUser: {
        accessToken: parsedCurrentUser.access_token,
        userDetails: parsedCurrentUser.user_details,
      },
    };
  }
  return {
    currentUser: {
      accessToken: null,
      userDetails: null,
    },
  };
};

export default {
  ...getCurrentUser(),
};
