import React from "react";
import style from "./style.module.scss";
import {CreationSource, TopicModel} from "../../classes/models.d";
import {IconButton} from "../icon-button/IconButton";
import {useDispatch} from "react-redux";
import {pushModal} from "../../features/active-modals/slice";
import {ModalIdentifier} from "../modals/modals-map";

interface TopicProps {
  customStyle?: React.CSSProperties;
  topic: TopicModel;
  onDelete?: () => void;
  isStakeholder?: boolean;
}

export const Topic: React.FC<TopicProps> = ({customStyle, topic, onDelete}) => {
  const dispatch = useDispatch();

  const openEditModal = () => {
    dispatch(
      pushModal({
        identifier: ModalIdentifier.Common.TopicModal,
        ComponentProps: {
          topic,
          onDelete,
        },
      }),
    );
  };

  const hasStakeholders = topic.stakeholders.length > 0;
  const firstStakeholder = topic.stakeholders[0];
  const withStakeholders = topic.stakeholders.slice(1);

  return (
    <div className={style.topic} style={customStyle}>
      <div className={style.topicHeader}>
        <div className={style.topicNameContainer}>
          <p className={style.topicName}>{topic.name}</p>
          {topic.creation_source == CreationSource.JIRA && (
            <div className={style.fromJiraLabel}>
              <span>
                <img src={"/assets/images/jira-colored.svg"} />{" "}
                <b>{topic.jira_issue_key}</b>
              </span>
            </div>
          )}
        </div>
        <IconButton
          className={style.optionsIcon}
          src={"/assets/images/settings-icon.svg"}
          width={25}
          onClick={openEditModal}
        />
      </div>
      <div className={style.topicContent}>
        {hasStakeholders ? (
          <>
            <p className={style.primaryStakeholderTitle}>PRIMARY STAKEHOLDER</p>
            <div style={{display: "flex", alignItems: "center"}}>
              <img
                key={firstStakeholder.id}
                className={style.primaryStakeholderImg}
                src={firstStakeholder.avatar_url}
              />
              <p className={style.primaryStakeholderName}>
                {firstStakeholder.display_name}
              </p>
            </div>
            {withStakeholders.length > 0 && (
              <div className={style.withStakeholdersContainer}>
                <p className={style.withStakeholdersTitle}>
                  WITH {withStakeholders.length}{" "}
                  {withStakeholders.length > 1 ? "OTHERS" : "OTHER"}
                </p>
                <div style={{display: "flex"}}>
                  {withStakeholders.slice(0, 5).map(stakeholder => (
                    <img
                      key={stakeholder.id}
                      className={style.otherStakeholderImg}
                      src={stakeholder.avatar_url}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <p className={style.primaryStakeholderTitle}>
            No stakeholders for this topic.
          </p>
        )}
      </div>
    </div>
  );
};
