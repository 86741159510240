import {initializeApp} from "firebase/app";
import Env from "./env/env";

const firebaseConfig = {
  apiKey: Env.FIREBASE_WEB_API_KEY,
  authDomain: Env.FIREBASE_AUTH_DOMAIN,
};

const App = initializeApp(firebaseConfig);

export default App;
