import React from "react";
import style from "./style.module.scss";

interface Props {
  width?: number;
}

export const Logo: React.FC<Props> = ({width = 50}) => {
  return (
    <div className={style.container}>
      <img src={"/assets/images/who2-icon.png"} width={width} />
    </div>
  );
};
