import React, {useCallback, useEffect, useState} from "react";
import style from "./style.module.scss";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../../../../classes/network/network-caller";
import ApiEndpoints from "../../../../../classes/network/api-endpoints";
import _ from "lodash";
import {
  StakeholderResourceType,
  UserSearchResultModel,
} from "../../../../../classes/models.d";

interface Props {
  excludedResults: UserSearchResultModel[];
  searchTerm: string;
  onSelectResult: (result: UserSearchResultModel) => void;
}

export const UserSearchTooltip: React.FC<Props> = ({
  excludedResults = [],
  searchTerm,
  onSelectResult,
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<UserSearchResultModel[]>(
    [],
  );

  useEffect(() => {
    if (searchTerm.length === 0) {
      setSearchResults([]);
      return;
    } else if (searchTerm.length === 1) {
      fetchResults();
    } else {
      fetchResultsWithDebounce();
      return fetchResultsWithDebounce.cancel;
    }
  }, [searchTerm]);

  const excludedUsersId = excludedResults
    .filter(result => result.resource_type == StakeholderResourceType.USER)
    .map(result => result.resource_id);

  const excludedJiraUsersId = excludedResults
    .filter(result => result.resource_type == StakeholderResourceType.JIRA_USER)
    .map(result => result.resource_id);

  const fetchResults = () => {
    setIsFetching(true);
    callRequest<UserSearchResultModel[]>({
      method: NetworkCallerMethod.GET,
      api: ApiEndpoints.SEARCH_USERS,
      data: {
        search_term: searchTerm,
        excluded_users_id: excludedUsersId,
        excluded_jira_users_id: excludedJiraUsersId,
      },
    }).then(response => {
      setSearchResults(response.data);
      setIsFetching(false);
    });
  };

  const fetchResultsWithDebounce = useCallback(
    _.debounce(() => {
      fetchResults();
    }, 500),
    [searchTerm],
  );

  if (searchResults.length == 0 && !isFetching) {
    return <></>;
  }

  return (
    <div className={style.container}>
      {isFetching && (
        <div className={style.loaderContainer}>
          <div className={`loader`} />
        </div>
      )}
      <div>
        {searchResults.map(searchResult => (
          <div
            onClick={() => onSelectResult(searchResult)}
            className={style.result}
            key={`${searchResult.resource_type}-${searchResult.resource_id}`}>
            <img src={searchResult.avatar_url} className={style.userAvatar} />
            <p>{searchResult.display_name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
