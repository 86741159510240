import React from "react";

import {getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../classes/network/network-caller";
import ApiEndpoints from "../../classes/network/api-endpoints";
import style from "./style.module.scss";
import {Button} from "../../shared-components/button/Button";
import {CurrentUserModel} from "../../classes/models";
import {useDispatch} from "react-redux";
import {setCurrentUser} from "../../features/current-user/slice";
import {Logo} from "../../shared-components/logo/Logo";
import Env from "../../classes/env/env";

const auth = getAuth();
const provider = new GoogleAuthProvider();

const GOOGLE_PROVIDER = "google";

export const Login = () => {
  const dispatch = useDispatch();

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider).then(result => {
      result.user.getIdToken(true).then(firebaseToken => {
        callRequest<CurrentUserModel>({
          method: NetworkCallerMethod.POST,
          api: ApiEndpoints.SIGN_IN,
          data: {
            firebase_id_token: firebaseToken,
            provider: GOOGLE_PROVIDER,
          },
        }).then(response => {
          dispatch(setCurrentUser(response.data));
        });
      });
    });
  };

  return (
    <div className={style.pageContainer}>
      <div className={`${style.logoBar} page-width`}>
        <Logo />
      </div>
      <div className={style.formContainer}>
        <div className={style.form}>
          <div>
            <div className={style.intoContainer}>
              <div className={style.welcomeContainer}>
                <img
                  className={style.welcomeImage}
                  src={"/assets/images/waving-hand.svg"}
                />
                <p className={style.welcomeText}>Welcome</p>
              </div>
              <div>
                <p className={style.readyText}>
                  Ready to easily find stakeholders in your workspace?
                </p>
              </div>
            </div>
            <Button
              onClick={signInWithGoogle}
              className={style.signInWithGoogle}
              variant={"filled-secondary"}
              iconUrl={"/assets/images/google-colored.svg"}>
              Continue with Google
            </Button>
          </div>
          <p className={style.disclaimer}>
            By continuing, you agree to our{" "}
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href={`${Env.WEBSITE_URL}/terms`}>
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              rel={"noreferrer"}
              target={"_blank"}
              href={`${Env.WEBSITE_URL}/privacy`}>
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};
