import Development from "./development";
import Production from "./production";
import {EnvModel} from "./env-model";

export const isDevelopment: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const Env: EnvModel = isDevelopment ? Development : Production;

export default Env;
