import {AttentionBox} from "../attention-box/AttentionBox";
import moment from "moment";
import React from "react";
import {ActiveSubscription} from "../../classes/models";
import style from "./style.module.scss";
import {useDispatch} from "react-redux";
import {ModalIdentifier} from "../modals/modals-map";
import {pushModal} from "../../features/active-modals/slice";

interface Props {
  activeSubscription: ActiveSubscription;
}

export const SubscriptionCancelScheduleAttentionBox: React.FC<Props> = ({
  activeSubscription,
}) => {
  const dispatch = useDispatch();

  const keepActive = () =>
    dispatch(
      pushModal({
        identifier:
          ModalIdentifier.SubscriptionPlan.ChangeSubscriptionConfirmationModal,
      }),
    );

  return (
    <AttentionBox type={"warning"} className={style.container}>
      <p className={style.title}>
        <b>Pending subscription cancellation</b>
      </p>
      <p>
        Your subscription will be canceled effective at{" "}
        <b>
          {moment(activeSubscription?.scheduled_change_effective_at).format(
            "MMM Do, YYYY",
          )}
        </b>
        .{" "}
        <span onClick={keepActive} className={style.keepItActiveButton}>
          Keep it active
        </span>
      </p>
    </AttentionBox>
  );
};
