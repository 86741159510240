import style from "./style.module.scss";
import {Plan} from "./plan/Plan";
import {
  ActiveSubscription,
  BillingCycle,
  SubscriptionPlan as SubscriptionPlanType,
} from "../../../../classes/models.d";
import React from "react";
import {
  SubscriptionPlanPricingPlans,
  SubscriptionPlans,
} from "../SubscriptionPlan";

export enum NextAction {
  CURRENT_PLAN,
  START_FREE_TRIAL,
  CHANGE_PLAN,
  CANCEL_SUBSCRIPTION,
}

const QUERIES_DESCRIPTION =
  "Queries are search requests (e.g., from Slack commands) that allow you to find stakeholders of a topic, based on a free text.";
const TOPIC_DESCRIPTION =
  "A topic is any area of focus within your organization, that team members can be associated with.";
const SLACK_INTEGRATION_DESCRIPTION =
  "Slack Integration allows you to find stakeholders of a topic directly from Slack, using a simple command.";
const JIRA_INTEGRATION_DESCRIPTION =
  "JIRA Integration offers a direct link between your JIRA workspace and Who2, automatically syncing epics as topics.";

interface Props {
  isLoading: boolean;
  activeSubscription: ActiveSubscription | undefined;
  plans: SubscriptionPlans | undefined;
  billingCycle: BillingCycle;
}

export const Plans: React.FC<Props> = ({
  isLoading,
  activeSubscription,
  plans,
  billingCycle,
}) => {
  const getPricePlan = (pricingPlans?: SubscriptionPlanPricingPlans) => {
    if (!pricingPlans) {
      return undefined;
    }
    return pricingPlans[billingCycle];
  };

  return (
    <div className={style.container}>
      <Plan
        title={"Starter"}
        isLoading={isLoading}
        plan={SubscriptionPlanType.STARTER}
        activeSubscription={activeSubscription}
        currentPlanPriceId={activeSubscription?.price_id}
        nextAction={
          activeSubscription
            ? activeSubscription?.plan_name === SubscriptionPlanType.STARTER
              ? NextAction.CURRENT_PLAN
              : NextAction.CANCEL_SUBSCRIPTION
            : NextAction.CURRENT_PLAN
        }
        pricePlan={undefined}
        billingCycle={billingCycle}
        description={"Get started with the basics."}
        features={[
          {
            title: "Unlimited queries",
            description: QUERIES_DESCRIPTION,
          },
          {
            title: "Maximum of 100 topics",
            description: TOPIC_DESCRIPTION,
          },
          {
            title: "Slack integration",
            description: SLACK_INTEGRATION_DESCRIPTION,
          },
        ]}
      />
      <Plan
        title={"Standard"}
        isLoading={isLoading}
        plan={SubscriptionPlanType.STANDARD}
        activeSubscription={activeSubscription}
        currentPlanPriceId={activeSubscription?.price_id}
        nextAction={
          activeSubscription?.plan_name === SubscriptionPlanType.STARTER
            ? NextAction.START_FREE_TRIAL
            : activeSubscription?.price_id ===
                getPricePlan(plans?.standard.pricing_plans)?.price_id
              ? NextAction.CURRENT_PLAN
              : NextAction.CHANGE_PLAN
        }
        pricePlan={getPricePlan(plans?.standard.pricing_plans)}
        billingCycle={billingCycle}
        description={"Built for medium-sized organizations."}
        features={[
          {
            title: "Unlimited queries",
            description: QUERIES_DESCRIPTION,
          },
          {
            title: "Maximum of 500 topics",
            description: TOPIC_DESCRIPTION,
          },
          {
            title: "Slack integration",
            description: SLACK_INTEGRATION_DESCRIPTION,
          },
          {
            title: "JIRA integration",
            description: JIRA_INTEGRATION_DESCRIPTION,
          },
        ]}
      />
      <Plan
        title={"Enterprise"}
        isLoading={isLoading}
        plan={SubscriptionPlanType.ENTERPRISE}
        activeSubscription={activeSubscription}
        currentPlanPriceId={activeSubscription?.price_id}
        nextAction={
          activeSubscription?.plan_name === SubscriptionPlanType.STARTER
            ? NextAction.START_FREE_TRIAL
            : activeSubscription?.price_id ===
                getPricePlan(plans?.enterprise.pricing_plans)?.price_id
              ? NextAction.CURRENT_PLAN
              : NextAction.CHANGE_PLAN
        }
        pricePlan={getPricePlan(plans?.enterprise.pricing_plans)}
        billingCycle={billingCycle}
        description={"Built for large organizations."}
        features={[
          {
            title: "Unlimited queries",
            description: QUERIES_DESCRIPTION,
          },
          {
            title: "Unlimited topics",
            description: TOPIC_DESCRIPTION,
          },
          {
            title: "Slack integration",
            description: SLACK_INTEGRATION_DESCRIPTION,
          },
          {
            title: "JIRA integration",
            description: JIRA_INTEGRATION_DESCRIPTION,
          },
        ]}
      />
    </div>
  );
};
