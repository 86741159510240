import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {OrganizationModel} from "../../classes/models";

interface OrganizationState {
  details: OrganizationModel | null;
}

const initialState: OrganizationState = {
  details: null,
};

export const slice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganizationDetails: (
      state,
      action: PayloadAction<OrganizationModel>,
    ) => {
      state.details = action.payload;
    },
  },
});

export const {setOrganizationDetails} = slice.actions;

export default slice.reducer;
