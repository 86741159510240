import React, {useEffect, useState} from "react";
import {UserSearchTooltip} from "./user-search-tooltip/UserSearchTooltip";
import {UserSearchResultModel} from "../../../../classes/models";
import style from "./style.module.scss";
import {MovableList, arrayMove, RenderItemParams} from "../../../moveble-list";

interface Props {
  defaultSearchResults?: UserSearchResultModel[];
  onChange: (searchResults: UserSearchResultModel[]) => void;
}

export const UserSearchInput: React.FC<Props> = ({
  onChange,
  defaultSearchResults = [],
}) => {
  const [selectedSearchResults, setSelectedSearchResults] =
    useState<UserSearchResultModel[]>(defaultSearchResults);

  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    onChange(selectedSearchResults);
  }, [selectedSearchResults]);

  const selectStakeholder = (searchResult: UserSearchResultModel) => {
    setSearchTerm("");
    setSelectedSearchResults(currentResults => [
      ...currentResults,
      searchResult,
    ]);
  };

  const removeSelectedResult = (resultToRemove: UserSearchResultModel) => {
    setSelectedSearchResults(currentSelectedSearchUsers =>
      currentSelectedSearchUsers.filter(
        currentResult =>
          currentResult.resource_type !== resultToRemove.resource_type ||
          currentResult.resource_id !== resultToRemove.resource_id,
      ),
    );
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const renderSearchResult = (
    params: RenderItemParams<UserSearchResultModel>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const props: any = params.props;
    return (
      <div
        {...props}
        className={`${style.selectedUser} ${
          params.index === selectedSearchResults.length - 1 &&
          style.noBorderBottom
        } ${params.isDragged && style.isDragging}`}>
        <div className={style.selectedUserNameContainer}>
          <img
            className={style.selectedUserAvatar}
            src={params.value.avatar_url}
          />
          <p className={style.selectedUserName}>{params.value.display_name}</p>
        </div>
        <img
          onMouseDown={event => event.stopPropagation()}
          onClick={event => {
            event.stopPropagation();
            removeSelectedResult(params.value);
          }}
          src={"/assets/images/close.svg"}
          className={style.selectedUserXIcon}
        />
      </div>
    );
  };

  const renderSearchResults = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: any,
    children: React.ReactNode,
  ) => (
    <div {...props} className={style.selectedUsers}>
      {children}
    </div>
  );

  return (
    <div>
      <div className={style.inputContainer}>
        <UserSearchTooltip
          onSelectResult={selectStakeholder}
          excludedResults={selectedSearchResults}
          searchTerm={searchTerm}
        />
        <input
          value={searchTerm}
          onChange={onInputChange}
          placeholder={"Search by name"}
          className={style.input}
        />
      </div>
      <MovableList
        values={selectedSearchResults}
        onChange={({oldIndex, newIndex}) =>
          setSelectedSearchResults(
            arrayMove(selectedSearchResults, oldIndex, newIndex),
          )
        }
        renderList={({children, props}) => (
          <>
            {selectedSearchResults.length > 0 &&
              renderSearchResults(props, children)}
          </>
        )}
        renderItem={renderSearchResult}
      />
    </div>
  );
};
