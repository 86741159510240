import React, {useState} from "react";
import style from "./style.module.scss";
import {Button} from "../../../../../shared-components/button/Button";
import {
  ActiveSubscription,
  BillingCycle,
  OrganizationCredentials,
  ScheduledChangeAction,
  SubscriptionManagementUrlResponse,
  SubscriptionPlan,
  ToastType,
} from "../../../../../classes/models.d";
import {Feature} from "./feature/Feature";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../../../../classes/network/network-caller";
import ApiEndpoints from "../../../../../classes/network/api-endpoints";
import {NextAction} from "../Plans";
import {PricePlan} from "../../SubscriptionPlan";
import {pushToast} from "../../../../../features/active-toasts/slice";
import {useDispatch} from "react-redux";
import {pushModal} from "../../../../../features/active-modals/slice";
import {ModalIdentifier} from "../../../../../shared-components/modals/modals-map";

interface Props {
  children?: any;
  title: string;
  plan: SubscriptionPlan;
  activeSubscription: ActiveSubscription | undefined;
  nextAction: NextAction;
  currentPlanPriceId: string | undefined;
  isLoading: boolean;
  pricePlan: PricePlan | undefined;
  billingCycle: BillingCycle;
  description: string;
  features: Feature[];
}

export const Plan: React.FC<Props> = ({
  title,
  isLoading,
  plan,
  activeSubscription,
  nextAction,
  currentPlanPriceId,
  pricePlan,
  billingCycle,
  description,
  features,
}) => {
  const dispatch = useDispatch();

  const [isPreparingCheckout, setIsPreparingCheckout] = useState(false);

  const handleAction = () => {
    if (nextAction === NextAction.CURRENT_PLAN) {
      return;
    }
    if (nextAction === NextAction.START_FREE_TRIAL) {
      createSubscription();
    } else if (nextAction === NextAction.CHANGE_PLAN) {
      changeSubscription();
    } else if (nextAction === NextAction.CANCEL_SUBSCRIPTION) {
      cancelSubscription();
    }
  };

  const createSubscription = () => {
    setIsPreparingCheckout(true);
    callRequest<OrganizationCredentials>({
      method: NetworkCallerMethod.GET,
      api: ApiEndpoints.ORGANIZATION_CREDENTIALS,
    }).then(response => {
      Paddle.Checkout.open({
        customData: {
          organization_client_id: response.data.client_id,
        },
        settings: {
          displayMode: "overlay",
          theme: "light",
          locale: "en",
          frameTarget: "checkout-container",
          frameInitialHeight: "450",
          frameStyle: "width: 100%;",
        },
        items: [
          {
            priceId: pricePlan?.price_id,
          },
        ],
      });
      setIsPreparingCheckout(false);
    });
  };

  const changeSubscription = () => {
    dispatch(
      pushModal({
        identifier: ModalIdentifier.SubscriptionPlan.ChangeSubscriptionModal,
        ComponentProps: {
          planName: plan,
          billingCycle: billingCycle,
          pricePlan: pricePlan,
        },
      }),
    );
  };

  const cancelSubscription = () => {
    if (
      activeSubscription?.scheduled_change_action ===
      ScheduledChangeAction.CANCEL
    ) {
      dispatch(
        pushToast({
          title: "You have already canceled your subscription",
          type: ToastType.ERROR,
        }),
      );
      return;
    }

    setIsPreparingCheckout(true);

    callRequest<SubscriptionManagementUrlResponse>({
      method: NetworkCallerMethod.POST,
      api: ApiEndpoints.CANCEL_SUBSCRIPTION,
      data: {
        price_id: pricePlan?.price_id,
      },
    }).then(response => {
      setIsPreparingCheckout(false);
      window.open(response.data.url, "_blank");
    });
  };

  const getMainActionLabel = () => {
    if (nextAction === NextAction.CURRENT_PLAN) {
      return "Current plan";
    } else if (nextAction === NextAction.START_FREE_TRIAL) {
      return "Start free trial";
    } else if (nextAction === NextAction.CHANGE_PLAN) {
      return "Change plan";
    } else if (nextAction === NextAction.CANCEL_SUBSCRIPTION) {
      return "Cancel subscription";
    }

    return "Current plan";
  };

  return (
    <div
      className={`${style.planContainer}`}
      style={
        activeSubscription?.plan_name === plan
          ? {borderColor: "black"}
          : undefined
      }>
      <div className={style.planTitleContainer}>
        <p className={style.planTitle}>{title}</p>
        {((plan === SubscriptionPlan.STARTER &&
          activeSubscription?.plan_name === SubscriptionPlan.STARTER) ||
          (currentPlanPriceId &&
            currentPlanPriceId == pricePlan?.price_id)) && (
          <span className={"badge success-invert"}>Active</span>
        )}
      </div>
      {!pricePlan && plan !== SubscriptionPlan.STARTER ? (
        <div className={style.priceLoader} />
      ) : (
        <p className={style.planPricing}>
          {plan === SubscriptionPlan.STARTER ? (
            "Free"
          ) : (
            <>
              ${pricePlan?.price}
              <span className={style.perMonthText}>
                / {billingCycle === BillingCycle.MONTHLY ? "month" : "year"}
              </span>
            </>
          )}
        </p>
      )}
      <p className={style.planDescription}>{description}</p>
      <div className={style.planFeatures}>
        {features.map(feature => (
          <Feature key={feature.title} feature={feature} />
        ))}
      </div>
      <div className={style.actionButtonContainer}>
        <Button
          enabled={!isLoading && nextAction != NextAction.CURRENT_PLAN}
          isLoading={isLoading || isPreparingCheckout}
          onClick={handleAction}
          className={style.actionButton}
          variant={
            nextAction === NextAction.CANCEL_SUBSCRIPTION
              ? "delete"
              : "filled-main"
          }>
          {getMainActionLabel()}
        </Button>
      </div>
    </div>
  );
};
