import React from "react";
import {Button} from "../button/Button";
import style from "./style.module.scss";

interface Props {
  options: {label: string; value: string}[];
  value: string;
  onSelect: (option: string) => void;
}

export const MultiSelector: React.FC<Props> = ({options, value, onSelect}) => {
  return (
    <div>
      <div className={style.container}>
        {options.map(option => (
          <Button
            className={style.item}
            key={option.value}
            onClick={() => onSelect(option.value)}
            variant={
              value == option.value ? "filled-main" : "filled-secondary"
            }>
            {option.label}
          </Button>
        ))}
      </div>
    </div>
  );
};
