import React, {useRef, useState} from "react";
import SimpleModal, {SimpleModalHandle} from "../simple-modal/SimpleModal";
import style from "../topic-modal/style.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUser} from "../../../features/current-user/selectors";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../../classes/network/network-caller";
import ApiEndpoints from "../../../classes/network/api-endpoints";
import {setCurrentUserDetails} from "../../../features/current-user/slice";
import {ModalModel, ToastType, UserModel} from "../../../classes/models.d";
import {pushToast} from "../../../features/active-toasts/slice";

interface Props {
  modalData: ModalModel;
}

export const OrganizationModal: React.FC<Props> = ({modalData}) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const organization =
    currentUser?.userDetails?.organization_member?.organization;

  const [name, setName] = useState<string>(organization?.name || "");
  const [logoUrl, setLogoUrl] = useState<string>(organization?.logo_url || "");
  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  const modalRef = useRef<SimpleModalHandle>(null);

  const onConfirm = () => {
    setIsConfirming(true);
    callRequest({
      method: "PUT",
      api: ApiEndpoints.MY_ORGANIZATION,
      data: {
        name,
        logo_url: logoUrl,
      },
    })
      .then(() => {
        callRequest<UserModel>({
          method: NetworkCallerMethod.GET,
          api: ApiEndpoints.ME,
        })
          .then(response => {
            dispatch(setCurrentUserDetails(response.data));
            dispatch(
              pushToast({
                title: "Details were updated successfully",
                type: ToastType.SUCCESS,
              }),
            );
            setIsConfirming(false);
            modalRef.current?.close();
          })
          .catch(() => {
            dispatch(
              pushToast({
                title: "Something went wrong",
                type: ToastType.ERROR,
              }),
            );
          });
      })
      .catch(() => {
        dispatch(
          pushToast({
            title: "Something went wrong",
            type: ToastType.ERROR,
          }),
        );
      });
  };

  const isConfirmButtonEnabled = name.length > 0;

  return (
    <SimpleModal
      ref={modalRef}
      modalData={modalData}
      title={"Edit Organization"}
      onConfirm={onConfirm}
      isConfirmButtonEnabled={isConfirmButtonEnabled}
      isConfirmButtonLoading={isConfirming}>
      <div>
        <p className={style.sectionTitle}>Organization name</p>
        <input
          defaultValue={organization?.name}
          onChange={event => setName(event.target.value)}
          placeholder={"Type here"}
          className={style.sectionInput}
        />
      </div>
      <div className={style.section}>
        <p className={style.sectionTitle}>Logo URL (optional)</p>
        <input
          defaultValue={organization?.logo_url}
          onChange={event => setLogoUrl(event.target.value)}
          placeholder={"Type here"}
          className={style.sectionInput}
        />
      </div>
    </SimpleModal>
  );
};
