import React, {useRef} from "react";
import SimpleModal, {SimpleModalHandle} from "../../../../shared-components/modals/simple-modal/SimpleModal";
import {ModalModel, Role, ToastType} from "../../../../classes/models.d";
import {UserAndPermissionMember} from "../../UsersAndPermissions";
import style from "./style.module.scss";
import {Toggle} from "../../../../shared-components/toggle/Toggle";
import {callRequest, NetworkCallerMethod} from "../../../../classes/network/network-caller";
import ApiEndpoints from "../../../../classes/network/api-endpoints";
import {useDispatch} from "react-redux";
import {pushToast} from "../../../../features/active-toasts/slice";
import {Selector} from "../../../../shared-components/selector/Selector";
import {AxiosError} from "axios/index";

interface Props {
  modalData: ModalModel;
  member: UserAndPermissionMember;
  onUpdate: (member: UserAndPermissionMember) => void;
}

export const ConfigureModal: React.FC<Props> = ({modalData, member, onUpdate}) => {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [role, setRole] = React.useState<Role>(member.role);
  const [isSuspended, setIsSuspended] = React.useState<boolean>(member.is_suspended);

  const modalRef = useRef<SimpleModalHandle>(null);

  const saveChanges = () => {
    setIsSaving(true);
    callRequest<UserAndPermissionMember>({
      method: NetworkCallerMethod.PUT,
      api: ApiEndpoints.ORGANIZATION_MEMBER(member.id),
      data: {
        role,
        is_suspended: isSuspended
      }
    }).then(response => {
      onUpdate(response.data);
      setIsSaving(false);
      dispatch(pushToast({
        title: "Member updated successfully",
        type: ToastType.SUCCESS
      }))
      modalRef.current?.close();
    }).catch((error: AxiosError<{ error: string; }>) => {
      setIsSaving(false);
      dispatch(pushToast({
        title: error.response?.data?.error || "Failed to update member",
        type: ToastType.ERROR
      }))
    })
  }

  return (
    <SimpleModal ref={modalRef} title={member.user.email} modalData={modalData} onConfirm={saveChanges} isConfirmButtonLoading={isSaving} confirmButtonText={'Update'}>
      <div className={style.section}>
        <p className={style.sectionTitle}>Role</p>
        <Selector
          options={[
            {
              label: "Admin",
              value: Role.ADMIN,
            },
            {
              label: "User",
              value: Role.USER,
            },
          ]}
          selectedOption={{
            label: role == Role.ADMIN ? "Admin" : "User",
            value: role,
          }}
          onSelect={value => setRole(value as Role)}
        />
      </div>
      <div className={style.section}>
        <div className={style.suspendAccountTitleContainer}>
          <p className={style.sectionTitle}>Suspend account</p>
          <Toggle isSelected={isSuspended} onSelect={setIsSuspended}/>
        </div>
        <p className={style.sectionSubTitle}>
          By suspending this account, the user will no longer be able to access this workspace.
        </p>
      </div>
    </SimpleModal>
  );
};
