import React, {useEffect, useState} from "react";
import {Screen} from "../../../shared-components/screen/Screen";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../../classes/network/network-caller";
import ApiEndpoints from "../../../classes/network/api-endpoints";
import {BillingTransaction} from "../../../classes/models";
import {Transaction} from "./transaction/Transaction";

export const Transactions = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [transactions, setTransactions] = useState<BillingTransaction[]>([]);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = () => {
    setIsLoading(true);
    callRequest<BillingTransaction[]>({
      method: NetworkCallerMethod.GET,
      api: ApiEndpoints.TRANSACTIONS,
    }).then(response => {
      setTransactions(response.data);
      setIsLoading(false);
    });
  };

  return (
    <Screen title="Transactions">
      {isLoading ? (
        <div className={"loader page-loader"} />
      ) : (
        <>
          {transactions.length == 0 ? (
            <p>No transactions found.</p>
          ) : (
            <div>
              {transactions.map(transaction => (
                <Transaction key={transaction.id} transaction={transaction} />
              ))}
            </div>
          )}
        </>
      )}
    </Screen>
  );
};
