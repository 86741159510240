import React, {useEffect, useState} from "react";
import SimpleModal from "../simple-modal/SimpleModal";
import style from "./style.module.scss";
import {Button} from "../../button/Button";
import Screens from "../../../classes/screens";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../features/current-user/selectors";
import {
  ActiveSubscription,
  ModalModel,
  ScheduledChangeAction,
  SubscriptionManagementUrlResponse,
  SubscriptionPlan,
} from "../../../classes/models.d";
import {AttentionBox} from "../../attention-box/AttentionBox";
import {callRequest, NetworkCallerMethod,} from "../../../classes/network/network-caller";
import ApiEndpoints from "../../../classes/network/api-endpoints";
import moment from "moment";
import {
  SubscriptionCancelScheduleAttentionBox
} from "../../subscription-cancel-schedule-attention-box/SubscriptionCancelScheduleAttentionBox";

interface Props {
  modalData: ModalModel;
}

export const BillingModal: React.FC<Props> = ({modalData}) => {
  const currentUser = useSelector(selectCurrentUser);

  const [isFetchingActiveSubscription, setIsFetchingActiveSubscription] =
    useState<boolean>(false);

  const [activeSubscription, setActiveSubscription] =
    useState<ActiveSubscription>();

  const [isLoadingUpdatePaymentMethod, setIsLoadingUpdatePaymentMethod] =
    useState<boolean>(false);

  useEffect(() => {
    fetchActiveSubscription();
  }, []);

  const fetchActiveSubscription = () => {
    setIsFetchingActiveSubscription(true);
    callRequest<ActiveSubscription>({
      method: NetworkCallerMethod.GET,
      api: ApiEndpoints.ACTIVE_SUBSCRIPTION,
    }).then(response => {
      setActiveSubscription(response.data);
      setIsFetchingActiveSubscription(false);
    });
  };

  const updatePaymentMethod = () => {
    setIsLoadingUpdatePaymentMethod(true);
    callRequest<SubscriptionManagementUrlResponse>({
      method: NetworkCallerMethod.POST,
      api: ApiEndpoints.UPDATE_PAYMENT_METHOD,
    }).then(response => {
      window.open(response.data.url, "_blank");
      setIsLoadingUpdatePaymentMethod(false);
    });
  };

  const nextBilledAt = moment(activeSubscription?.next_billed_at).format(
    "MMM Do, YYYY",
  );

  const plan: SubscriptionPlan =
    currentUser?.userDetails?.organization_member?.organization
      ?.subscription_plan || SubscriptionPlan.STARTER;

  const plainPlanText =
    plan === SubscriptionPlan.STANDARD ? "standard" : "enterprise";

  const hasActiveSubscription = [SubscriptionPlan.STANDARD, SubscriptionPlan.ENTERPRISE].includes(plan);

  return (
    <SimpleModal modalData={modalData} title={"Billing"}>
      <div>
        {isFetchingActiveSubscription ? (
          <div className={`loader ${style.loader}`} />
        ) : (
          <>
            {activeSubscription?.scheduled_change_action ===
            ScheduledChangeAction.CANCEL ? (
              <SubscriptionCancelScheduleAttentionBox
                activeSubscription={activeSubscription}
              />
            ) : (
              <>
                {!hasActiveSubscription ? (
                  <AttentionBox type={"warning"} bold={true}>
                    <p>You are on the free plan</p>
                  </AttentionBox>
                ) : (
                  <AttentionBox type={"success"}>
                    <p>You have an active {plainPlanText} plan</p>
                  </AttentionBox>
                )}
              </>
            )}
            {activeSubscription && activeSubscription?.next_billed_at && (
              <p className={style.nextBilledAtText}>
                Next payment is on <b>{nextBilledAt}</b>.
              </p>
            )}
          </>
        )}
        <div>
          <Button
            href={Screens.MANAGE_SUBSCRIPTION_PLAN}
            className={style.button}>
            Manage subscription
          </Button>
        </div>
      </div>
      <div>
        <Button
          href={Screens.TRANSACTIONS}
          className={style.button}
          variant={"filled-secondary"}>
          Billing history
        </Button>
      </div>
      {
        hasActiveSubscription && (
          <div>
            <Button
              className={style.button}
              onClick={updatePaymentMethod}
              variant={"filled-secondary"}
              isLoading={isLoadingUpdatePaymentMethod}>
              Update payment method
            </Button>
          </div>
        )
      }
    </SimpleModal>
  );
};
