import React, {useEffect, useState} from "react";
import {Screen} from "../screen/Screen";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../classes/network/network-caller";
import ApiEndpoints from "../../classes/network/api-endpoints";
import {Topic} from "../topic/Topic";
import {StakeholderResourceType, TopicModel} from "../../classes/models.d";
import style from "./style.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {selectTopicCreated} from "../../features/topic-created/selectors";
import {createTopic} from "../../features/topic-created/slice";
import {selectCurrentUser} from "../../features/current-user/selectors";

export enum TopicsScreenType {
  ALL_TOPICS = "ALL_TOPICS",
  MY_TOPICS = "MY_TOPICS",
}

interface Props {
  screenType: TopicsScreenType;
}

export const Topics: React.FC<Props> = ({screenType}) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [topics, setTopics] = useState<TopicModel[]>([]);

  const createdTopic = useSelector(selectTopicCreated);

  useEffect(() => {
    fetchTopics();
  }, []);

  useEffect(() => {
    if (createdTopic) {
      const isInvolvedTopic = createdTopic.stakeholders.some(
        stakeholder =>
          stakeholder.resource_type == StakeholderResourceType.USER &&
          stakeholder.resource_id == currentUser.userDetails?.id,
      );
      if (
        (screenType == TopicsScreenType.MY_TOPICS && isInvolvedTopic) ||
        screenType == TopicsScreenType.ALL_TOPICS
      ) {
        setTopics([...topics, createdTopic]);
      }
      dispatch(createTopic(null));
    }
  }, [createdTopic]);

  const fetchTopics = () => {
    callRequest<TopicModel[]>({
      method: NetworkCallerMethod.GET,
      api:
        screenType === TopicsScreenType.ALL_TOPICS
          ? ApiEndpoints.TOPICS
          : ApiEndpoints.MY_TOPICS,
    }).then(response => {
      setTopics(response.data);
      setIsFetching(false);
    });
  };

  const deleteTopic = (topicId: number) => {
    setTopics(topics.filter(topic => topic.id !== topicId));
  };

  return (
    <Screen
      title={
        screenType === TopicsScreenType.ALL_TOPICS
          ? "All topics"
          : "Topics I'm involved in"
      }
      titleIconUrl={
        screenType === TopicsScreenType.ALL_TOPICS
          ? "/assets/images/all-topics-icon.svg"
          : "/assets/images/my-topics-icon.svg"
      }>
      {isFetching && <div className={"loader page-loader"} />}
      {!isFetching && topics.length == 0 && (
        <p>
          {screenType === TopicsScreenType.ALL_TOPICS
            ? "All the topics will appear here."
            : "Topics you're involved in will appear here."}
        </p>
      )}
      <div className={style.topics}>
        {topics.map((topic, index) => (
          <Topic
            customStyle={{animationDelay: `${index * 0.01}s`}}
            key={topic.id}
            onDelete={() => deleteTopic(topic.id)}
            topic={topic}
          />
        ))}
      </div>
    </Screen>
  );
};
