import {ToastModelWithoutId} from "./models";

export const REDIRECT_TOAST_TITLE = "redirect_toast_title";
export const REDIRECT_TOAST_TYPE = "redirect_toast_type";
export const REDIRECT_TOAST_BODY = "redirect_toast_body";
export const REDIRECT_TOAST_DURATION = "redirect_toast_duration";

export const generateRedirectToastParams = (toast: ToastModelWithoutId) => {
  const title = toast.title
    ? `${REDIRECT_TOAST_TITLE}=${encodeURIComponent(toast.title)}`
    : "";
  const type = toast.type
    ? `${REDIRECT_TOAST_TYPE}=${encodeURIComponent(toast.type)}`
    : "";
  const body = toast.body
    ? `${REDIRECT_TOAST_BODY}=${encodeURIComponent(toast.body)}`
    : "";
  const duration = toast.duration
    ? `${REDIRECT_TOAST_DURATION}=${encodeURIComponent(toast.duration)}`
    : "";
  return `${title}&${type}&${body}&${duration}`;
};
