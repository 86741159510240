import React, {useEffect} from "react";

import {useLocation} from "react-router-dom";
import style from "./style.module.scss";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../classes/network/network-caller";
import ApiEndpoints from "../../classes/network/api-endpoints";
import Screens from "../../classes/screens";
import {ToastType} from "../../classes/models.d";
import {generateRedirectToastParams} from "../../classes/utils";
import {TabScreen} from "../settings/Settings";

export const JiraOauthRedirect = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const error = queryParams.get("error");

  useEffect(() => {
    connectUserToJira();
  }, []);

  const connectUserToJira = () => {
    if (code) {
      callRequest({
        method: NetworkCallerMethod.POST,
        api: ApiEndpoints.JIRA_USER_SIGN_IN,
        data: {
          code,
        },
      })
        .then(() => {
          const toastParams = generateRedirectToastParams({
            type: ToastType.SUCCESS,
            title: "JIRA connected successfully",
          });
          window.location.href = `${Screens.SETTINGS}?tab=${TabScreen.PERSONAL}&${toastParams}`;
        })
        .catch(() => {
          redirectError();
        });
    } else if (error && error.length > 0) {
      redirectError();
    }
  };

  const redirectError = () => {
    window.location.href = `${Screens.SETTINGS}?tab=${
      TabScreen.PERSONAL
    }&${generateRedirectToastParams({
      title: "Failed to connect to JIRA",
      type: ToastType.ERROR,
    })}`;
  };

  return (
    <div className={style.container}>
      <div>
        <img src={"/assets/images/jira-colored.svg"} />
      </div>
      <p>Connecting your JIRA account to your Who2 account...</p>
    </div>
  );
};
