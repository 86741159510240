import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ToastModel, ToastModelWithoutId} from "../../classes/models";
import {generateUUID} from "../../classes/common-utils";

interface ActiveToastsState {
  activeToasts: ToastModel[];
}

const initialState: ActiveToastsState = {
  activeToasts: [],
};

export const slice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    pushToast: (
      state: ActiveToastsState,
      action: PayloadAction<ToastModelWithoutId>,
    ) => {
      state.activeToasts.unshift({
        id: generateUUID(),
        ...action.payload,
      });
    },
    removeToast: (
      state: ActiveToastsState,
      action: PayloadAction<ToastModel>,
    ) => {
      state.activeToasts = state.activeToasts.filter(
        toast => toast.id != action.payload.id,
      );
    },
    clearToasts: (state: ActiveToastsState) => {
      state.activeToasts = [];
    },
  },
});

export const {pushToast, removeToast, clearToasts} = slice.actions;

export default slice.reducer;
