import {SubscriptionPlanAlertModal} from "./subscription-plan-alert-modal/PromptModal";
import {ConfirmationModal} from "../subscription-cancel-schedule-attention-box/confirmation-modal/ConfirmationModal";
import {ChangeSubscriptionModal} from "../../screens/billing/subscription-plan/plans/plan/change-subscription-modal/ChangeSubscriptionModal";
import {BillingModal} from "./billing-modal/BillingModal";
import {OrganizationModal} from "./organization-modal/OrganizationModal";
import {TopicModal} from "./topic-modal/TopicModal";
import {DeleteTopicModal} from "./topic-modal/delete-topic-modal/DeleteTopicModal";
import {ConfigureModal} from "../../screens/users-and-permissions/member/configure-modal/ConfigureModal";

export const ModalIdentifier = {
  Common: {
    BillingModal: "common.billing-modal",
    OrganizationModal: "common.organization-modal",
    TopicModal: "common.create-topic",
    DeleteTopicModal: "common.delete-topic",
  },
  UsersAndPermissions: {
    ConfigureModal: "users-and-permissions.configure-modal",
  },
  SubscriptionPlan: {
    AlertModal: "subscription-plan.alert-modal",
    ChangeSubscriptionModal: "subscription-plan.change-modal",
    ChangeSubscriptionConfirmationModal:
      "subscription-plan.change-confirmation-modal",
  },
} as const;

export const ModalComponents = {
  [ModalIdentifier.Common.BillingModal]: BillingModal,
  [ModalIdentifier.Common.OrganizationModal]: OrganizationModal,
  [ModalIdentifier.Common.TopicModal]: TopicModal,
  [ModalIdentifier.Common.DeleteTopicModal]: DeleteTopicModal,
  [ModalIdentifier.SubscriptionPlan.AlertModal]: SubscriptionPlanAlertModal,
  [ModalIdentifier.SubscriptionPlan.ChangeSubscriptionConfirmationModal]:
    ConfirmationModal,
  [ModalIdentifier.SubscriptionPlan.ChangeSubscriptionModal]:
    ChangeSubscriptionModal,
  [ModalIdentifier.UsersAndPermissions.ConfigureModal]: ConfigureModal,
};
