import React, {PropsWithChildren} from "react";
import style from "./style.module.scss";

interface ScreenProps {
  withTopPadding?: boolean;
  title?: string;
  titleIconUrl?: string;
}

export const Screen: React.FC<PropsWithChildren<ScreenProps>> = ({
  withTopPadding = true,
  title,
  titleIconUrl,
  children,
}) => {
  return (
    <div
      className={`page-width ${style.container} ${
        withTopPadding && style.withTopPadding
      }`}>
      {title && (
        <div className={style.pageTitleContainer}>
          <h1
            className={`${style.pageTitle} ${
              titleIconUrl && style.withMargin
            }`}>
            {title}
          </h1>
        </div>
      )}
      <div
        className={`${style.children} ${
          withTopPadding && style.withTopPadding
        }`}>
        {children}
      </div>
    </div>
  );
};
