import React, {PropsWithChildren, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import style from "./style.module.scss";
import {ToastModel, ToastType} from "../../classes/models.d";
import {useDispatch} from "react-redux";
import {removeToast} from "../../features/active-toasts/slice";

interface Props {
  data: ToastModel;
}

export const Toast: React.FC<PropsWithChildren<Props>> = ({data}) => {
  const dispatch = useDispatch();

  const ANIMATION_DURATION = data.duration || 2000;

  const [isAnimatingOut, setIsAnimatingOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimatingOut(true);
    }, ANIMATION_DURATION);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isAnimatingOut) {
      setTimeout(() => {
        dispatch(removeToast(data));
      }, ANIMATION_DURATION);
    }
  }, [isAnimatingOut]);

  const getIconByType = () => {
    if (data.type == ToastType.SUCCESS) {
      return "/assets/images/pricing-checkmark.svg";
    }
    if (data.type == ToastType.ERROR) {
      return "/assets/images/error-icon.svg";
    }
  };

  return ReactDOM.createPortal(
    <div className={`${style.wrapper} ${isAnimatingOut && style.animatingOut}`}>
      <div className={style.toast}>
        <img className={style.icon} src={getIconByType()} width={25} />
        <div className={style.toastContent}>
          {data.title && <p className={style.toastTitle}>{data.title}</p>}
          {data.body && <p className={style.toastBody}>{data.body}</p>}
        </div>
      </div>
    </div>,
    document.body,
  );
};
