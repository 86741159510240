export default {
  MY_TOPICS: "/",
  ALL_TOPICS: "/all-topics",
  LOGIN: "/login",
  SETTINGS: "/settings",
  SELECT_ORGANIZATION: "/select-organization",
  SLACK_USER_SIGN_IN_REDIRECT: "/slack/user/sign-in/redirect",
  SLACK_INTEGRATION_REDIRECT: "/slack/organization/integrate/redirect",
  JIRA_OAUTH_REDIRECT: "/jira/oauth/redirect",
  BILLING_PAYMENT: "/billing/payment",
  TRANSACTIONS: "/billing/transactions",
  MANAGE_SUBSCRIPTION_PLAN: "/billing/subscription-plan/manage",
  USERS_AND_PERMISSIONS: "/users-and-permissions",
};
