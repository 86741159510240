import React, {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import style from "./style.module.scss";
import {IconButton} from "../../icon-button/IconButton";
import {Button, ButtonVariant} from "../../button/Button";
import {useDispatch} from "react-redux";
import {removeModal} from "../../../features/active-modals/slice";
import {ModalModel} from "../../../classes/models";

export const SIMPLE_MODAL_ANIMATION_DURATION = 300;

export type SimpleModalHandle = {
  close: () => void;
};

interface Props {
  modalData: ModalModel;
  isSmallModal?: boolean;
  customWidth?: number;
  title?: string;
  withModalContainerContentPadding?: boolean;
  additionalFooterContent?: React.ReactNode;
  showCloseButton?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void | undefined;
  confirmButtonText?: string;
  confirmButtonVariant?: ButtonVariant;
  isConfirmButtonLoading?: boolean;
  isConfirmButtonEnabled?: boolean;
  cancelButtonVariant?: ButtonVariant;
  cancelButtonText?: string;
}

const SimpleModal = forwardRef<SimpleModalHandle, PropsWithChildren<Props>>(
  (
    {
      modalData,
      children,
      isSmallModal,
      customWidth,
      title,
      showCloseButton = true,
      onConfirm,
      onCancel,
      confirmButtonText,
      confirmButtonVariant = "filled-main",
      cancelButtonVariant = "just-text",
      cancelButtonText = "Cancel",
      isConfirmButtonLoading,
      isConfirmButtonEnabled = true,
      additionalFooterContent,
      withModalContainerContentPadding = true,
    },
    ref,
  ) => {
    const dispatch = useDispatch();

    const [isAnimatingOut, setIsAnimatingOut] = useState(false);

    useEffect(() => {
      if (isAnimatingOut) {
        const timer = setTimeout(() => {
          setIsAnimatingOut(false);
          dispatch(removeModal(modalData));
        }, SIMPLE_MODAL_ANIMATION_DURATION);
        return () => clearTimeout(timer);
      }
    }, [isAnimatingOut]);

    const animateAndClose = () => {
      setIsAnimatingOut(true);
    };

    useImperativeHandle(ref, () => ({
      close: animateAndClose,
    }));

    return (
      <div className={style.center}>
        <div
          className={`${style.modalOverlay} ${
            isAnimatingOut && style.animatingOut
          }`}
        />
        <div
          className={`${style.modalContainer} 
          ${isSmallModal && style.smallModalContainer} ${
            isAnimatingOut && style.animatingOut
          } ${withModalContainerContentPadding && style.withPadding}`}
          style={
            customWidth
              ? {maxWidth: customWidth, minWidth: customWidth}
              : undefined
          }>
          {showCloseButton && (
            <div className={style.modalCloseContainer}>
              <IconButton
                onClick={animateAndClose}
                src={"/assets/images/close.svg"}
                width={20}
              />
            </div>
          )}
          {title && <p className={style.modalTitle}>{title}</p>}
          <div
            className={`${style.modalContent} ${
              showCloseButton && title && style.withMargin
            }`}>
            {children}
          </div>
          {(onConfirm || onCancel) && (
            <div className={style.footerContainer}>
              {additionalFooterContent}
              <div className={style.mainButtons}>
                <Button
                  onClick={() => (onCancel ? onCancel() : animateAndClose())}
                  variant={cancelButtonVariant}>
                  {cancelButtonText || "Cancel"}
                </Button>
                <Button
                  enabled={isConfirmButtonEnabled}
                  onClick={onConfirm}
                  isLoading={isConfirmButtonLoading}
                  className={style.createTopicButton}
                  variant={confirmButtonVariant}>
                  {confirmButtonText || "Confirm"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);

SimpleModal.displayName = "SimpleModal";

export default SimpleModal;
