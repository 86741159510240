import {EnvModel} from "./env-model";

const Production: EnvModel = {
  API_URL: "https://api.who2.io",
  WEBSITE_URL: "https://who2.io",
  WEBAPP_URL: "https://console.who2.io",
  FIREBASE_WEB_API_KEY: "AIzaSyAms3GY9BZPlEsjUb8IXhcR8Qp2hWGD3js",
  FIREBASE_AUTH_DOMAIN: "who2-production.firebaseapp.com",
  SLACK_CLIENT_ID: "6409621814548.6407059728291",
  JIRA_CLIENT_ID: "Zzgh9tkULxDCjFQoCI90nifnvm8cES5s",
  PADDLE_ENV: "production",
  PADDLE_CLIENT_TOKEN: "live_1046d4c88e624286d44fed6b378",
};

export default Production;
