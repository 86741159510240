import React from "react";
import {UserAndPermissionMember} from "../UsersAndPermissions";
import style from "./style.module.scss";
import {Role} from "../../../classes/models.d";
import {Button} from "../../../shared-components/button/Button";
import {useDispatch} from "react-redux";
import {pushModal} from "../../../features/active-modals/slice";
import {ModalIdentifier} from "../../../shared-components/modals/modals-map";

interface Props {
  data: UserAndPermissionMember;
}

export const Member: React.FC<Props> = ({data}) => {
  const dispatch = useDispatch();

  const [member, setMember] = React.useState<UserAndPermissionMember>(data);

  const configure = () => {
    dispatch(
      pushModal({
        identifier: ModalIdentifier.UsersAndPermissions.ConfigureModal,
        ComponentProps: {
          member: data,
          onUpdate: (member: UserAndPermissionMember) => setMember(member)
        },
      }),
    );
  };

  return (
    <tr>
      <td className={style.tdContainer}>
        <div className={style.userContainer}>
          <div className={style.userImageContainer}>
            <img className={style.userImage} src={member.user.avatar_url} />
          </div>
          <div className={style.userNameContainer}>
            <p className={style.userName}>
              {member.user.name}
              { member.is_suspended && (
                <span className={`badge error ${style.suspendedBadge}`}>SUSPENDED</span>
              )}
            </p>
            <p className={style.userEmail}>{member.user.email}</p>
          </div>
        </div>
      </td>
      <td className={style.tdContainer}>
        <p className={style.role}>
          {member.role === Role.ADMIN ? "Admin" : "User"}
        </p>
      </td>
      <td className={style.tdContainer}>
        <Button onClick={configure} variant={"outlined"}>
          Configure
        </Button>
      </td>
    </tr>
  );
};
