import {configureStore} from "@reduxjs/toolkit";
import currentUserReducer from "./features/current-user/slice";
import organizationReducer from "./features/organization/slice";
import topicCreatedReducer from "./features/topic-created/slice";
import activeToastsReducer from "./features/active-toasts/slice";
import activeModalsReducer from "./features/active-modals/slice";
import preLoadedState from "./pre-loaded-state";

export const store = configureStore({
  preloadedState: preLoadedState,
  reducer: {
    currentUser: currentUserReducer,
    organization: organizationReducer,
    topicCreated: topicCreatedReducer,
    activeToasts: activeToastsReducer,
    activeModals: activeModalsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
