import CurrentUser from "../current-user";
import {isDevelopment} from "../env/env";

const INTERCOM_SCRIPT_ID = "intercom-script";

export const loadIntercom = () => {
  if (isDevelopment) {
    return;
  }

  const script = document.createElement("script");
  script.id = INTERCOM_SCRIPT_ID;
  script.src = "/assets/scripts/intercom-script.js";
  script.onload = () => {
    const currentUser = CurrentUser.getCurrentUser()?.user_details || {};
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "mzqermp4",
      user_id: currentUser.uuid,
      user_hash: currentUser.intercom_user_hash,
      name: currentUser.name,
    });
  };
  document.head.appendChild(script);
};

export const removeIntercomScript = () => {
  if (isDevelopment) {
    return;
  }

  window.Intercom("update", {
    hide_default_launcher: true,
  });
};
