import React, {useState} from "react";
import style from "./style.module.scss";
import moment from "moment";
import {Button} from "../../../../shared-components/button/Button";
import {
  BillingTransaction,
  BillingTransactionInvoice,
} from "../../../../classes/models";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../../../classes/network/network-caller";
import ApiEndpoints from "../../../../classes/network/api-endpoints";

interface Props {
  transaction: BillingTransaction;
}

export const Transaction: React.FC<Props> = ({transaction}) => {
  const [isInvoiceLoading, setIsInvoiceLoading] = useState<boolean>(false);

  const displayTransactionInvoice = () => {
    setIsInvoiceLoading(true);
    callRequest<BillingTransactionInvoice>({
      method: NetworkCallerMethod.GET,
      api: ApiEndpoints.TRANSACTION_INVOICE(transaction.id),
      data: {
        transaction_id: transaction.id,
      },
    }).then(response => {
      window.open(response.data.url, "_blank");
      setIsInvoiceLoading(false);
    });
  };

  return (
    <div className={style.billingTransaction}>
      <p className={style.transactionDate}>
        {moment(transaction.billed_at).format("MM.DD.YYYY")} — $
        {transaction.amount}
      </p>
      <div>
        <Button
          onClick={displayTransactionInvoice}
          isLoading={isInvoiceLoading}
          variant={"outlined"}
          className={style.invoiceButton}>
          Invoice
        </Button>
      </div>
    </div>
  );
};
