import React, {useRef, useState} from "react";
import SimpleModal, {
  SimpleModalHandle,
} from "../../../../../../shared-components/modals/simple-modal/SimpleModal";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../../../../../classes/network/network-caller";
import ApiEndpoints from "../../../../../../classes/network/api-endpoints";
import {pushToast} from "../../../../../../features/active-toasts/slice";
import {
  BillingCycle,
  ModalModel,
  SubscriptionPlan,
  ToastType,
} from "../../../../../../classes/models.d";
import {useDispatch} from "react-redux";
import {PricePlan} from "../../../SubscriptionPlan";
import style from "./style.module.scss";

interface Props {
  modalData: ModalModel;
  planName: SubscriptionPlan;
  billingCycle: BillingCycle;
  pricePlan: PricePlan | undefined;
  onClose: () => void;
}

export const ChangeSubscriptionModal: React.FC<Props> = ({
  modalData,
  planName,
  billingCycle,
  pricePlan,
}) => {
  const dispatch = useDispatch();

  const ref = useRef<SimpleModalHandle>(null);

  const [isConfirming, setIsConfirming] = useState(false);

  const confirm = () => {
    setIsConfirming(true);
    callRequest({
      method: NetworkCallerMethod.POST,
      api: ApiEndpoints.CHANGE_SUBSCRIPTION,
      data: {
        price_id: pricePlan?.price_id,
      },
    }).then(() => {
      ref.current?.close();
      dispatch(
        pushToast({
          title: "Your subscription has been changed successfully",
          type: ToastType.SUCCESS,
        }),
      );
      setIsConfirming(false);
    });
  };

  const formattedPlanName =
    planName == SubscriptionPlan.STANDARD ? "Standard" : "Enterprise";

  const formattedBillingCycle =
    billingCycle == BillingCycle.MONTHLY ? "monthly" : "annually";

  return (
    <SimpleModal
      ref={ref}
      modalData={modalData}
      title={"Change plan"}
      onConfirm={confirm}
      isConfirmButtonLoading={isConfirming}>
      <div className={style.container}>
        <p>
          You are about to change your subscription plan to the{" "}
          <b>{formattedPlanName}</b> plan.
        </p>
        <p>
          You will be billed <b>${pricePlan?.price}</b> {formattedBillingCycle}.
        </p>
        <p>
          Since you're changing plans partway through your current billing
          cycle, we'll calculate the cost difference and apply a prorated charge
          immediately.
        </p>
      </div>
    </SimpleModal>
  );
};
