import React, {useState} from "react";
import style from "./style.module.scss";

interface Option {
  label: string;
  value: string;
}

interface Props {
  options: Option[];
  selectedOption: Option;
  onSelect: (option: string) => void;
}

export const Selector: React.FC<Props> = ({ options, selectedOption, onSelect }) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleSelector = () => setIsOpen(!isOpen);

  return (
    <div className={style.container}>
      <div onClick={toggleSelector} className={style.selector}>
        <p>{ selectedOption.label }</p>
        <img src={'/assets/images/arrow-down.svg'} width={15} />
      </div>
      <div className={`${style.options} ${isOpen && style.open}`}>
        {
          options.map(option => (
            <div onClick={() => {
              onSelect(option.value);
              setIsOpen(false);
            }} key={option.value} className={style.option}>
              <p>{ option.label }</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}
