import React, {useEffect} from "react";
import "./App.scss";
import {Routes, Route, useLocation, Navigate} from "react-router-dom";
import Screens from "./classes/screens";
import {SlackIntegrationRedirect} from "./screens/slack/organization-integration-redirect/SlackIntegrationRedirect";
import {MenuBar} from "./shared-components/menu-bar/MenuBar";
import {MyTopics} from "./screens/my-topics/MyTopics";
import {Settings} from "./screens/settings/Settings";
import {Login} from "./screens/login/Login";
import {SelectOrganization} from "./screens/select-organization/SelectOrganization";
import {SlackUserSignInRedirect} from "./screens/slack/user-sign-in-redirect/SlackUserSignInRedirect";
import CurrentUser from "./classes/current-user";
import {useDispatch, useSelector} from "react-redux";
import {fetchCurrentUserDetails} from "./api/user-api";
import {selectCurrentUser} from "./features/current-user/selectors";
import {setCurrentUserDetails} from "./features/current-user/slice";
import {selectActiveToasts} from "./features/active-toasts/selectors";
import {Toast} from "./shared-components/toast/Toast";
import {AllTopics} from "./screens/all-topics/AllTopics";
import {JiraOauthRedirect} from "./screens/jira-oauth-redirect/JiraOauthRedirect";
import {clearToasts, pushToast} from "./features/active-toasts/slice";
import {ToastType} from "./classes/models.d";
import {
  REDIRECT_TOAST_BODY,
  REDIRECT_TOAST_DURATION,
  REDIRECT_TOAST_TITLE,
  REDIRECT_TOAST_TYPE,
} from "./classes/utils";
import {SubscriptionPlan} from "./screens/billing/subscription-plan/SubscriptionPlan";
import {Payment} from "./screens/billing/payment/Payment";
import {Transactions} from "./screens/billing/transactions/Transactions";
import {selectActiveModals} from "./features/active-modals/selectors";
import {clearModals} from "./features/active-modals/slice";
import {ModalComponents} from "./shared-components/modals/modals-map";
import {UsersAndPermissions} from "./screens/users-and-permissions/UsersAndPermissions";

const ROUTES_TO_HIDE_MENU_BAR = [
  Screens.LOGIN,
  Screens.SELECT_ORGANIZATION,
  Screens.SLACK_USER_SIGN_IN_REDIRECT,
  Screens.SLACK_INTEGRATION_REDIRECT,
  Screens.JIRA_OAUTH_REDIRECT,
];

declare global {
  interface Window {
    Intercom: any;
  }
  const Paddle: {
    Checkout: {
      open: (options?: any) => void;
    };
    Environment: {
      set: (env: string) => void;
    };
    Setup: (options: any) => void;
  };
}

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const currentUser = useSelector(selectCurrentUser);
  const activeToasts = useSelector(selectActiveToasts);
  const activeModals = useSelector(selectActiveModals);

  useEffect(() => {
    dispatch(clearToasts());
    dispatch(clearModals());
  }, [location.pathname]);

  useEffect(() => {
    handleRedirectMessage();
    if (CurrentUser.isSignedIn()) {
      fetchCurrentUserDetails().then(data => {
        dispatch(setCurrentUserDetails(data));
      });
    }
  }, []);

  const handleRedirectMessage = () => {
    const redirectToastTitle = queryParams.get(REDIRECT_TOAST_TITLE);
    const redirectToastType = queryParams.get(REDIRECT_TOAST_TYPE);
    const redirectToastBody = queryParams.get(REDIRECT_TOAST_BODY);
    const redirectToastDuration = queryParams.get(REDIRECT_TOAST_DURATION);
    if (redirectToastTitle) {
      dispatch(
        pushToast({
          title: redirectToastTitle,
          body: redirectToastBody || undefined,
          duration: redirectToastDuration
            ? parseInt(redirectToastDuration)
            : undefined,
          type: redirectToastType as ToastType,
        }),
      );
    }
  };

  const shouldShowMenuBar = !ROUTES_TO_HIDE_MENU_BAR.includes(
    location.pathname,
  );

  const requiredAuthRoute = (reactNode: React.ReactNode) => {
    if (CurrentUser.isSignedIn()) {
      if (currentUser.userDetails?.organization_member) {
        return reactNode;
      }
      return <Navigate to={Screens.SELECT_ORGANIZATION} replace={true} />;
    }
    return <Navigate to={Screens.LOGIN} replace={true} />;
  };

  const selectOrganizationRoute = (reactNode: React.ReactNode) => {
    if (CurrentUser.isSignedIn()) {
      if (currentUser.userDetails?.organization_member) {
        return <Navigate to={Screens.ALL_TOPICS} replace={true} />;
      }
      return reactNode;
    }
    return <Navigate to={Screens.LOGIN} replace={true} />;
  };

  const renderActiveToasts = () => {
    return activeToasts.activeToasts.map(data => (
      <Toast key={data.id} data={data} />
    ));
  };

  const renderModals = () => {
    return activeModals.activeModals.map((data, index) => {
      const Component =
        ModalComponents[data.identifier as keyof typeof ModalComponents];
      const zIndex = 1000 + index;
      return (
        <div key={data.id} className={"super-modal-container"} style={{zIndex}}>
          <Component modalData={data} {...data.ComponentProps} />;
        </div>
      );
    });
  };

  return (
    <div>
      <div>{renderActiveToasts()}</div>
      <div>{renderModals()}</div>
      {shouldShowMenuBar && <MenuBar />}
      <Routes>
        <Route
          path={Screens.ALL_TOPICS}
          element={requiredAuthRoute(<AllTopics />)}
        />
        <Route
          path={Screens.MY_TOPICS}
          element={requiredAuthRoute(<MyTopics />)}
        />
        <Route
          path={Screens.SETTINGS}
          element={requiredAuthRoute(<Settings />)}
        />
        <Route
          path={Screens.SELECT_ORGANIZATION}
          element={selectOrganizationRoute(<SelectOrganization />)}
        />
        <Route
          path={Screens.SLACK_USER_SIGN_IN_REDIRECT}
          element={<SlackUserSignInRedirect />}
        />
        <Route
          path={Screens.SLACK_INTEGRATION_REDIRECT}
          element={<SlackIntegrationRedirect />}
        />
        <Route
          path={Screens.JIRA_OAUTH_REDIRECT}
          element={<JiraOauthRedirect />}
        />
        <Route
          path={Screens.MANAGE_SUBSCRIPTION_PLAN}
          element={<SubscriptionPlan />}
        />
        <Route path={Screens.BILLING_PAYMENT} element={<Payment />} />
        <Route path={Screens.TRANSACTIONS} element={<Transactions />} />
        <Route
          path={Screens.USERS_AND_PERMISSIONS}
          element={<UsersAndPermissions />}
        />
        <Route
          path={Screens.LOGIN}
          element={
            CurrentUser.isSignedIn() ? (
              <Navigate to={Screens.ALL_TOPICS} replace={true} />
            ) : (
              <Login />
            )
          }
        />
        <Route path="*" element={<Navigate to={Screens.ALL_TOPICS} />} />
      </Routes>
    </div>
  );
}

export default App;
