import React, {useState} from "react";
import style from "./style.module.scss";
import Env from "../../classes/env/env";
import Screens from "../../classes/screens";
import {useLocation, useNavigate} from "react-router-dom";
import CurrentUser from "../../classes/current-user";
import {Button} from "../../shared-components/button/Button";
import {AttentionBox} from "../../shared-components/attention-box/AttentionBox";
import {Screen} from "../../shared-components/screen/Screen";
import {Logo} from "../../shared-components/logo/Logo";
import {useDispatch} from "react-redux";
import {pushToast} from "../../features/active-toasts/slice";
import {ToastType, UserModel} from "../../classes/models.d";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../classes/network/network-caller";
import ApiEndpoints from "../../classes/network/api-endpoints";
import {setCurrentUserDetails} from "../../features/current-user/slice";

export const SelectOrganization = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const [organizationName, setOrganizationName] = useState<string>("");
  const [isCreatingOrganization, setIsCreatingOrganization] =
    useState<boolean>(false);

  const getSignInWithSlackRedirectUrl = () => {
    const clientId = Env.SLACK_CLIENT_ID;
    const redirectUri = encodeURIComponent(
      `${Env.WEBAPP_URL}${Screens.SLACK_USER_SIGN_IN_REDIRECT}`,
    );
    const scopes = "users.profile:read";
    return `https://slack.com/oauth/v2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes}`;
  };

  const createOrganization = () => {
    if (organizationName.length == 0) {
      dispatch(
        pushToast({
          title: "Organization name cannot be empty",
          type: ToastType.ERROR,
        }),
      );
      return;
    }
    setIsCreatingOrganization(true);
    callRequest({
      method: NetworkCallerMethod.POST,
      api: ApiEndpoints.CREATE_ORGANIZATION,
      data: {
        name: organizationName,
      },
    })
      .then(() => {
        callRequest<UserModel>({
          method: NetworkCallerMethod.GET,
          api: ApiEndpoints.ME,
        }).then(response => {
          setIsCreatingOrganization(false);
          dispatch(
            pushToast({
              title: "Organization created successfully",
              type: ToastType.SUCCESS,
            }),
          );
          dispatch(setCurrentUserDetails(response.data));
        });
      })
      .catch(() => {
        setIsCreatingOrganization(false);
        dispatch(
          pushToast({
            title: "Failed to create organization",
            type: ToastType.ERROR,
          }),
        );
      });
  };

  return (
    <Screen withTopPadding={false}>
      <div className={style.header}>
        <div>
          <a onClick={() => CurrentUser.signOut(navigate)} className={"button"}>
            Sign out
          </a>
        </div>
      </div>
      <div className={style.centeredContainer}>
        <div className={style.formContainer}>
          <Logo />
          <div>
            <h1>You are not associated with any organization</h1>
            <div>
              <p>Join your organization with your Slack workspace</p>
              <Button
                href={getSignInWithSlackRedirectUrl()}
                variant={"slack"}
                iconUrl={"/assets/images/slack-colored.svg"}>
                Sign in with Slack
              </Button>
              {queryParams.get("error_message") && (
                <div className={style.errorMessageContainer}>
                  <AttentionBox type={"error"}>
                    <p>{queryParams.get("error_message")}</p>
                  </AttentionBox>
                </div>
              )}
            </div>
            <div className={style.orOptionContainer}>
              <div className={style.orDivider}>
                <div className={style.orDividerLine} />
                <span className={style.orText}>OR</span>
                <div className={style.orDividerLine} />
              </div>
              <div>
                <p>Create an organization</p>
                <input
                  onChange={event => setOrganizationName(event.target.value)}
                  className={style.organizationNameInput}
                  placeholder={"Organization name"}
                />
                <div>
                  <Button
                    isLoading={isCreatingOrganization}
                    onClick={createOrganization}
                    variant={"filled-main"}>
                    Create organization
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Screen>
  );
};
