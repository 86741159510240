import React from "react";
import style from "./style.module.scss";

interface Props {
  className?: string;
  src: string;
  width?: number;
  height?: number;
  onClick: () => void;
}

export const IconButton: React.FC<Props> = ({
  className,
  src,
  width,
  height = width,
  onClick,
}) => {
  return (
    <button
      className={`${style.container} ${className}`}
      style={{minWidth: width, minHeight: height}}
      onClick={onClick}>
      <img src={src} width={width} height={height} />
    </button>
  );
};
