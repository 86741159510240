import Screens from "./screens";
import Env from "./env/env";

const CHECKOUT_CLOSED_EVENT = "checkout.closed";

export const loadPaddle = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
    script.onload = () => {
      Paddle.Environment.set(Env.PADDLE_ENV);
      Paddle.Setup({
        token: Env.PADDLE_CLIENT_TOKEN,
        eventCallback: (event: {name: string}) => {
          if (
            event.name === CHECKOUT_CLOSED_EVENT &&
            window.location.pathname === Screens.BILLING_PAYMENT
          ) {
            window.location.href = Screens.MY_TOPICS;
          }
        },
      });

      resolve(true);
    };
    script.onerror = () => reject();
    document.head.appendChild(script);
  });
};
