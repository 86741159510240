import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {generateUUID} from "../../classes/common-utils";
import {ModalModel, ModalModelWithoutId} from "../../classes/models";

interface ActiveModalsState {
  activeModals: ModalModel[];
}

const initialState: ActiveModalsState = {
  activeModals: [],
};

export const slice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    pushModal: (
      state: ActiveModalsState,
      action: PayloadAction<ModalModelWithoutId>,
    ) => {
      state.activeModals.push({
        id: generateUUID(),
        ...action.payload,
      });
    },
    removeModal: (
      state: ActiveModalsState,
      action: PayloadAction<ModalModel>,
    ) => {
      state.activeModals = state.activeModals.filter(
        modal => modal.id != action.payload.id,
      );
    },
    clearModals: (state: ActiveModalsState) => {
      state.activeModals = [];
    },
  },
});

export const {pushModal, removeModal, clearModals} = slice.actions;

export default slice.reducer;
