import React from "react";
import {Screen} from "../../shared-components/screen/Screen";
import style from "./style.module.scss";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../features/current-user/selectors";
import {Role} from "../../classes/models.d";
import {OrganizationTabScreen} from "./tabs-screen/organization-tab-screen/OrganizationTabScreen";
import {PersonalTabScreen} from "./tabs-screen/PersonalTabScreen";
import {useLocation, useNavigate} from "react-router-dom";
import Screens from "../../classes/screens";
import {MultiSelector} from "../../shared-components/multi-selector/MultiSelector";

export enum TabScreen {
  ORGANIZATION = "organization",
  PERSONAL = "personal",
}

export const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const currentUser = useSelector(selectCurrentUser);

  const isAdmin =
    currentUser.userDetails?.organization_member?.role == Role.ADMIN;

  const selectedTab = isAdmin
    ? queryParams.get("tab") || TabScreen.ORGANIZATION
    : TabScreen.PERSONAL;

  const selectTab = (tab: TabScreen) => {
    navigate({
      pathname: Screens.SETTINGS,
      search: `?tab=${tab}`,
    });
  };

  return (
    <Screen
      title={"Settings"}
      titleIconUrl={"/assets/images/settings-icon.svg"}>
      {isAdmin && (
        <MultiSelector
          options={[
            {
              label: "Organization",
              value: TabScreen.ORGANIZATION,
            },
            {
              label: "Personal",
              value: TabScreen.PERSONAL,
            },
          ]}
          value={selectedTab}
          onSelect={value => selectTab(value as TabScreen)}
        />
      )}
      <div className={style.tabsScreenContainer}>
        {selectedTab == TabScreen.ORGANIZATION && <OrganizationTabScreen />}
        {selectedTab == TabScreen.PERSONAL && <PersonalTabScreen />}
      </div>
      <div className={style.supportTextContainer}>
        <a
          href={"https://www.who2.io/support"}
          target={"_blank"}
          rel={"noreferrer"}>
          Support
        </a>
      </div>
    </Screen>
  );
};
