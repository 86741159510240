import React, {PropsWithChildren} from "react";
import style from "./style.module.scss";

interface Props {
  className?: string;
  type: "error" | "info" | "success" | "warning";
  bold?: boolean;
}

export const AttentionBox: React.FC<PropsWithChildren<Props>> = ({
  className,
  children,
  bold,
  type,
}) => {
  const getIconByType = () => {
    if (type == "error") {
      return "/assets/images/error-info-icon.svg";
    }
    if (type == "info") {
      return "/assets/images/warning.svg";
    }
    if (type == "warning") {
      return "/assets/images/warning.svg";
    }
    if (type == "success") {
      return "/assets/images/pricing-checkmark.svg";
    }
  };

  return (
    <div className={`${style.container} ${style[type]} ${className}`}>
      <img src={getIconByType()} width={30} />
      <div className={`${bold && style.bold}`}>{children}</div>
    </div>
  );
};
