import {
  callRequest,
  NetworkCallerMethod,
} from "../classes/network/network-caller";
import ApiEndpoints from "../classes/network/api-endpoints";
import {UserModel} from "../classes/models";

export const fetchCurrentUserDetails = async (): Promise<UserModel> => {
  const response = await callRequest<UserModel>({
    method: NetworkCallerMethod.GET,
    api: ApiEndpoints.ME,
  });
  return response.data;
};
