import {EnvModel} from "./env-model";

const Development: EnvModel = {
  API_URL: "https://api.whoto.io.ngrok.app",
  WEBSITE_URL: "https://who2.io",
  WEBAPP_URL: "https://webapp.whoto.io.ngrok.app",
  FIREBASE_WEB_API_KEY: "AIzaSyDZlhwmQL4QM4Crgm3t3lTA-XDPRyHffRs",
  FIREBASE_AUTH_DOMAIN: "who2-development.firebaseapp.com",
  SLACK_CLIENT_ID: "6409621814548.6484380267895",
  JIRA_CLIENT_ID: "Ur3AGie3XCYxg1ldFg5u9PZ5JkZ6bujx",
  PADDLE_ENV: "sandbox",
  PADDLE_CLIENT_TOKEN: "test_c6a936217c1bc9803693fba4e5d",
};

export default Development;
